import "./cushionPage.css";
import Header from "../header/header";
import React, {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import {AcPhone} from "../icon/AcPhone";
import {useDispatch, useSelector} from "react-redux";
import Icon from "../icon/icon";
import {setShowVirtualBackgroundModal} from "../../services/virtualBackground/virtualBackgroundSlice";
import JoinMeetingButton, {IconJoinMeetingButton} from "../styled/JoinMeetingButton";
import {Box, LinearProgress, linearProgressClasses} from "@mui/material";
import {type CustomHTMLVideoElement} from "../../services/type/customerType";
import {type AppDispatch, type RootState} from "../../store";
import {type FeaturesRef} from "../features/features";
import {type Connection, getListConnectionsByChannel} from "../../services/sora/soraApi";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {useTranslation} from "react-i18next";
import {RECVONLY} from "../../constants/constant";
import {stringFormat} from "../../utils/utils";
import {
    setEnqueueSnackbarKeyEmptyRoom,
    setJoinMeetingButtonDisabled,
    setOpenErrorCameraDialog, setOpenErrorMicDialog
} from "../../services/sora/soraSlice";
import {closeSnackbar} from "notistack";
import Loading from "../loading/Loading";

interface CushionPageProps {
    connectSendrecv: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>
    handleClickMicIcon: () => Promise<void>
    handleClickCameraIcon: () => Promise<void>
    handleClickOpenErrorDevice: any
    handleClickCloseErrorDevice: any
    featuresRef: React.RefObject<FeaturesRef>
}

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 2,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#CCCCCC"
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: "#33BB33"
    }
}));

export default function CushionPage (props: CushionPageProps) {
    const videoRef = useRef<CustomHTMLVideoElement>(null);
    const {
        localMediaStream,
        isCameraOn,
        isMicroOn,
        channelId,
        isJoinMeetingButtonDisabled,
        connectType,
        roomName,
        enqueueSnackbarKeyEmptyRoom,
        isErrorCamera,
        isErrorMic,
        isMirroringWebcam,
        isInitializingStream
    } = useSelector((state: RootState) => state.sora);
    const {cameras, micros, speakers, deviceUsing, isLoading} = useSelector((state: RootState) => state.devices);
    const {isShowVirtualBackgroundModal} = useSelector((state: RootState) => state.virtualBackground);
    const dispatch = useDispatch<AppDispatch>();
    const [listConnections, setListConnections] = useState<Connection[]>([]);
    const [volumeMic, setVolumeMic] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        let intervalGetListConnections: any;
        if (channelId) {
            getListConnectionsByChannel(channelId).then(connections => {
                setListConnections(connections);
            }).catch(e => {
                console.log(e);
            });
            intervalGetListConnections = setInterval(() => {
                getListConnectionsByChannel(channelId).then(connections => {
                    setListConnections(connections);
                }).catch(e => {
                    console.log(e);
                });
            }, 5000);
        }

        return () => {
            clearInterval(intervalGetListConnections);
        };
    }, [channelId]);

    useEffect(() => {
        const showVideo = async () => {
            const video = videoRef.current;
            if (video !== null && localMediaStream != null && localMediaStream.active) {
                video.autoplay = true;
                video.srcObject = localMediaStream;
            }
        };
        showVideo().then(null).catch(null);
    }, [localMediaStream]);

    useEffect(() => {
        if (!localMediaStream) {
            return;
        }
        if (localMediaStream.getAudioTracks().length === 0) {
            return;
        }
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        const mediaStreamSource = audioContext.createMediaStreamSource(localMediaStream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        mediaStreamSource.connect(analyser);

        let animationFrameId: number | null = null;
        function draw (): void {
            animationFrameId = requestAnimationFrame(draw);
            analyser.getByteTimeDomainData(dataArray);
            const array = Array.from(dataArray);
            // dataArray の最低値が 128 のため、最小値を 0 にする
            const currentVolume = Math.max.apply(null, array) - 128;
            setVolumeMic((currentVolume / 128) * 100);
        }
        draw();
        return () => {
            if (audioContext) {
                audioContext.close().then(null).catch(null);
            }
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [localMediaStream]);

    useEffect(() => {
        if (listConnections.length > 0) {
            if (enqueueSnackbarKeyEmptyRoom) {
                dispatch(setJoinMeetingButtonDisabled(false));
                dispatch(setEnqueueSnackbarKeyEmptyRoom(undefined));
                closeSnackbar(enqueueSnackbarKeyEmptyRoom);
            }
        }
    }, [listConnections]);

    const handleOpenErrorCameraDialog = () => {
        dispatch(setOpenErrorCameraDialog(true));
    };

    const handleOpenErrorMicDialog = () => {
        dispatch(setOpenErrorMicDialog(true));
    };

    return <div className="cushion-page">
        <Header/>
        <div className="cushion">
            <div className="student-hangout">
                <div className="room-name">
                    <span className="student-hangout-text">＃{roomName}</span>
                    <div className="user-in-call">
                        <span className="list-connections-length">{stringFormat(t("people-currently-in-the-room"), listConnections.length.toString())}</span>
                    </div>
                </div>
                <div className="join-meeting-btn">
                    <JoinMeetingButton className="join-meeting-button-desktop" onClick={(event) => { props.connectSendrecv(event).then(null).catch(null); }}
                                       variant="contained" startIcon={<AcPhone width="20px" />} disabled={isJoinMeetingButtonDisabled || !isLoading || isInitializingStream}>
                        { connectType === RECVONLY ? t("start-a-call-recvonly") : t("start-a-call")}
                    </JoinMeetingButton>
                    <IconJoinMeetingButton className="join-meeting-button-mobile" onClick={(event) => { props.connectSendrecv(event).then(null).catch(null); }}
                                disabled={isJoinMeetingButtonDisabled || !isLoading || isInitializingStream} size="large">
                        <AcPhone height="24px" />
                    </IconJoinMeetingButton>
                </div>
            </div>
            <div className="media-stream">
                <video className={`${isMirroringWebcam ? "mirroring-webcam" : ""}`} ref={videoRef} hidden={!isCameraOn} muted playsInline={true}/>
                <img className="img-user-default" src="/img/ac-user.png" alt="User" hidden={isCameraOn || connectType === RECVONLY}/>
                {
                    connectType !== RECVONLY &&
                    <div className="setting-media-stream">
                        {
                            (micros.length === 0 && isLoading) || isErrorMic
                                ? <Icon handleOnclick={isErrorMic ? handleOpenErrorMicDialog : props.handleClickOpenErrorDevice} titleTooltip={t("title-toolTip")}
                                      classCustom="bg-red">
                                    <img src="/img/ac-mic-mute.svg" alt="mic"/>
                                    <div className="error-device">
                                        <PriorityHighIcon className="error-device-icon"></PriorityHighIcon>
                                    </div>
                                </Icon>
                                : isMicroOn
                                    ? <Icon handleOnclick={props.handleClickMicIcon} titleTooltip={t("turn-off-the-mic")}>
                                        <img src="/img/ac-mic.svg" alt="mic"/>
                                    </Icon>
                                    : <Icon handleOnclick={props.handleClickMicIcon} titleTooltip={t("turn-on-the-mic")}
                                            classCustom="bg-red">
                                        <img src="/img/ac-mic-mute.svg" alt="mic"/>
                                    </Icon>
                        }
                        {
                            (cameras.length === 0 && isLoading) || isErrorCamera
                                ? <Icon handleOnclick={isErrorCamera ? handleOpenErrorCameraDialog : props.handleClickOpenErrorDevice} titleTooltip={t("title-toolTip")}
                                      classCustom="bg-red">
                                    <img src="/img/ac-video.svg" alt="video"/>
                                    <div className="error-device">
                                        <PriorityHighIcon className="error-device-icon"></PriorityHighIcon>
                                    </div>
                                </Icon>
                                : isCameraOn
                                    ? <Icon handleOnclick={props.handleClickCameraIcon}
                                            titleTooltip={t("turn-off-the-camera")}>
                                        <img src="/img/ac-video.svg" alt="video"/>
                                    </Icon>
                                    : <Icon handleOnclick={props.handleClickCameraIcon} titleTooltip={t("turn-on-the-camera")}
                                          classCustom="bg-red">
                                        <img src="/img/ac-video-mute.svg" alt="video"/>
                                    </Icon>
                        }
                        <Icon handleOnclick={() => {
                            dispatch(setShowVirtualBackgroundModal(!isShowVirtualBackgroundModal));
                        }}
                              titleTooltip={t("effect-settings")}>
                            <img src="/img/ac-stars-outline.svg" alt="stars outline"/>
                        </Icon>
                    </div>
                }
                <div className="loading-media" hidden={!isInitializingStream}>
                    <div className="loading-media-center">
                        <Loading width="60px" height="60px"/>
                        <span className="loading-media-text">{t("Please wait a moment before entering the room.")}</span>
                    </div>
                </div>
            </div>
            <div className="setting-devices">
                <div className="select-devices">
                    <div className="devices" hidden={connectType === RECVONLY}>
                        <img src="/img/ac-video-select.svg" alt="video"/>
                        <img className="active" src="/img/ac-video-active.svg" alt="video"/>
                        {
                            props.featuresRef.current &&
                            <select value={deviceUsing.camera?.deviceId}
                                    onChange={props.featuresRef.current.handleChangeCamera}>
                                {
                                    cameras.map((item, index) => {
                                        return <option key={index} value={item.deviceId}>{item.label}</option>;
                                    })
                                }
                            </select>
                        }
                    </div>
                    <div className="devices" hidden={connectType === RECVONLY}>
                        <img src="/img/ac-mic-select.svg" alt="mic"/>
                        <img className="active" src="/img/ac-mic-active.svg" alt="mic"/>
                        {
                            props.featuresRef.current &&
                            <select value={deviceUsing.micro?.deviceId}
                                    onChange={props.featuresRef.current.handleChangeMic}>
                                {
                                    micros.map((item, index) => {
                                        return <option key={index} value={item.deviceId}>{item.label}</option>;
                                    })
                                }
                            </select>
                        }
                        <Box sx={{width: "100%"}}>
                            <CustomLinearProgress variant="determinate" value={volumeMic}/>
                        </Box>
                    </div>
                    <div className="devices" hidden={connectType === RECVONLY}>
                        <img src="/img/ac-speaker-outline.svg" alt="speaker-outline"/>
                        <img className="active" src="/img/ac-speaker-outline-active.svg" alt="speaker-outline"/>
                        {
                            props.featuresRef.current &&
                            <select value={deviceUsing.speaker?.deviceId}
                                    onChange={props.featuresRef.current.handleChangeSpeaker}>
                                {
                                    speakers.map((item, index) => {
                                        return <option key={index} value={item.deviceId}>{item.label}</option>;
                                    })
                                }
                            </select>
                        }
                        <Box sx={{ width: "100%" }}>
                            <CustomLinearProgress variant="determinate" value={50} />
                        </Box>
                    </div>
                </div>
                <span dangerouslySetInnerHTML={{__html: t("click-here-for-the-support-site")}} hidden={connectType === RECVONLY}/>
            </div>
        </div>
    </div>;
}
