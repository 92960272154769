const en = {
    translation: {
        "title-toolTip": "さらに情報を表示",
        "button-device-error": "Close",
        "button-device-cancel": "Cancel",
        "device-message-error-first": "When the confirmation dialog appears, please ",
        "device-message-error-allow": "Allow ",
        "device-message-error-second": "the use of the microphone and camera.",
        "device-message-error-third": "If the issue is not resolved, please refer to the ",
        "device-message-error-link": "support ",
        "device-message-error-last": "site.",
        "device-message-error-img": "/img/ac-device-error-modal-en.png",
        "request-has-been-sent": "Your entry request has been sent. Please wait while your request is granted.",
        "request-denied": "I was not allowed to enter. <br>Alternatively, there may have been a problem with your request to enter the room because the owner of the room is absent, so please try to enter the room again after a while.",
        "request-to-enter-the-room-has-arrived": "A request to enter the room has arrived from {0}",
        "allow-entry": "Allow entry",
        "not-allowed": "Not allowed",
        "requesting-if-no-one-is-in-the-room": "You sent an entry request, but there were no users in the room who could grant the request. Please wait a moment and try requesting entry again.",
        "wording-when-permission-to-enter-the-room-is-not-obtained-for-about-30-seconds": "I sent a request to enter, but did not receive a response. Please try to enter the room again after a while.",
        logout: "Logout",
        login: "Login",
        login_auto_join: "Log in and start calling",
        "people-currently-in-the-room": "{0} people are currently in the room",
        "start-a-call": "Start a call",
        "start-a-call-recvonly": "Start a call",
        "click-here-for-the-support-site": "Click <a target=\"_blank\" href=\"https://aircamp.us/support/system_manuals/6657/\" rel=\"noreferrer\">here</a> for the support site.",
        "turn-off-the-mic": "Turn off the mic",
        "turn-on-the-mic": "Turn on the mic",
        "turn-off-the-camera": "Turn off the camera",
        "turn-on-the-camera": "Turn on the camera",
        "effect-settings": "Effect settings",
        "no-effect-blur": "No effect & blur",
        backgrounds: "Backgrounds",
        "sound-setting-mode": "Sound setting mode",
        "share-screen": "Share screen",
        "stop-screen-sharing": "Stop screen sharing",
        "you have shared your screen": "{0} screen is shared with everyone.",
        "you have stopped screen sharing": "{0} has stopped screen sharing.",
        chat: "Chat",
        "close-chat": "Close chat",
        "participant-list": "Participant list",
        "close-participant-list": "Close participant list",
        option: "Option",
        setting: "Video and audio settings",
        camera: "Camera",
        microphone: "Microphone",
        speaker: "Speaker",
        record: "Record",
        party: "Party",
        firework: "Firework",
        "leave-call": "Leave call",
        "Enlarged to fit everyone's screen": "Enlarged to fit everyone's screen",
        "Reduce the screen that is stretched to everyone's screen": "Reduce the screen that is stretched to everyone's screen",
        "Screen is shared with everyone": "{0} screen is shared with everyone",
        timer: "Timer",
        start: "Start",
        stop: "Stop",
        "start a call": "Start a call",
        "start a call the guest": "If you want to start the call as a guest, please enter the guest name.",
        "start a call ID AirCampus": "If you have an AirCampus ID, please log in before starting the call.",
        "or start call": "Or",
        name: "Guest name",
        cancel: "Cancel",
        "message on call": "Message on call",
        "select all": "All",
        "send message": "Send message",
        "Download Current Participant List": "Download Current Participant List",
        "Sort by volume": "Sort by volume",
        "Error call StopRecording": "Error call StopRecording",
        "Error call StartRecording": "Error call StartRecording",
        "You could not enter the room because the number of people who can enter was exceeded.": "入室可能な人数を超えたため、入室できませんでした。",
        "An error has occurred": "An error has occurred",
        "Recording was started": "Recording was started by {0}.",
        "Recording was stopped": "Recording was stopped by {0}.",
        "It was enlarged": "It was enlarged by {0}.",
        "It was reduced": "It was reduced by {0}.",
        "The timer was canceled": "The timer was canceled by {0}.",
        "A timer has been set": "A timer has been set by {0}.",
        "Error call ListConnections": "Error call ListConnections",
        "Error call list rooms": "Error call list rooms",
        "my room": "My room",
        "For system administrators": "For system administrators",
        "Current usage": "Current usage",
        "Aoba-BBT, Inc.": "Aoba-BBT, Inc.",
        "privacy policy": "Privacy policy",
        "terms of service": "Terms of service",
        "create room": "Create room",
        "room name": "Room name",
        "Room ID": "Room ID",
        "Access right": "Access right",
        Create: "Create",
        Update: "Update",
        Anyone: "Anyone",
        "Only users with an AirCampus account": "Only users with an AirCampus account",
        "owner only": "Owner only",
        delete: "Delete",
        change: "Change",
        "update room": "{0} details",
        Owner: "Owner",
        "delete room": "Delete room",
        "Can I delete the room?": "Can I delete the room?",
        "You can not delete the room": "You can not delete the room",
        person: "({0} person)",
        detail: "Detail",
        copied: "Copied",
        "URL for viewing": "URL for viewing",
        "entered the room": "{0} entered the room.",
        "has left the room": "{0} has left the room.",
        "Video file": "Video",
        "Created date": "Create date",
        "Error call list record": "Error call list record",
        "It will take some time until the recording file can be downloaded. Please wait.": "※It will take some time until the recording file can be downloaded. Please wait.",
        "meta-content": "This is the URL to enter {0} of AirCampus Session.",
        "very stable network": "very stable network",
        "stable network": "stable network",
        "unstable network": "unstable network",
        "very unstable network": "very unstable network",
        "loading network": "loading network",
        "video quality": "Video quality",
        "sub-title": "Subtitle",
        "Noise Suppression": "Noise Suppression",
        "You can enter if the owner approves": "You can enter if the owner approves",
        "You can enter the room if the AirCampus user who is in the room approves it": "You can enter the room if the AirCampus user who is in the room approves it",
        "If you know the URL, you can enter the room without approval": "If you know the URL, you can enter the room without approval",
        "AirCampus User": "AirCampus User",
        guest: "Guest",
        "Users who can enter the room": "Users who can enter the room",
        "Guests are not allowed to enter without authorization": "Guests are not allowed to enter without authorization",
        managers: "User with same permissions as owner",
        "Up to 3 people with the same authority as the owner can register": "Up to 3 people with the same authority as the owner can register.",
        "Acting owner": "Acting owner",
        "Please enter your ID": "Please enter your ID",
        Search: "Search",
        Add: "Add",
        "room details": "{0} details",
        "sub room": "Sub room",
        owner: "Owner",
        "Error call list access log": "Error call list access log",
        "Session ID": "Session ID",
        "Account ID": "Account ID",
        "Account Name": "Account Name",
        "Created Time": "Created Time",
        "Destroyed Time": "Destroyed Time",
        User: "User",
        "Audio file": "Audio",
        "This is a URL that allows you to participate only by viewing. People who enter the room from this URL can join the room as viewing-only guests.": "This is a URL that allows you to participate only by viewing. People who enter the room from this URL can join the room as viewing-only guests.",
        "Breakout Rooms": "Member assignment of {0}",
        "Breakout Rooms notice": "Check the members you want to assign to {0}.",
        "no members to assign": "There are no members to assign.",
        "be-applicable": "Applicable",
        "user has joined room.": "{0} has joined {1}.",
        "title breakout room": "Breakout room",
        "title breakout main room": "Main room",
        "title breakout side room": "Breakout room",
        "assign-members": "Assign members",
        "not-participant": "No members assigned.",
        "add breakout room": "Add group",
        "breakout session time": "Breakout session time",
        "start join breakout room": "Start a breakout session",
        "note breakout room": "Owners and alternate owners must select and enter their own breakout rooms.",
        "input adornment end": "(point)",
        "Editing is not allowed": "※Please change from the Session details screen.",
        "Allow user pin": "Make the zoom feature available to users and guests",
        "Beauty Effects": "Beauty Effects",
        "Effect strength": "Effect strength",
        "A network disconnection has been detected. Please wait while we try to reconnect.": "A network disconnection has been detected. Please wait while we try to reconnect.",
        "Mirroring webcam": "Mirroring webcam",
        "Turn off the mic": "Turn off the mic",
        "Detailed settings for this Session": "Detailed settings for this Session",
        "Progress rate": "Progress",
        confirmation: "Confirmation",
        "Do you want to delete the recording file?": "Do you want to delete the recording file?",
        "Update record": "Update record",
        share: "Share",
        "share record": "Share record",
        "shared account": "Shared account",
        Download: "Download",
        "Select where to save the recording file": "Select where to save the recording file",
        "Start recording": "Start recording",
        "The recording data will be saved in the recording folder of the selected user.": "The recording data will be saved in the recording folder of the selected user.",
        Summary: "Summary",
        "Create summary": "Create summary",
        "View Summary": "View Summary",
        Close: "Close",
        "Edit video": "Edit video",
        "Start position": "Start position",
        "Crop duration (s)": "Crop duration (s)",
        "Crop selected area": "Crop selected area",
        reset: "Reset",
        "Confirm Crop": "Confirm Crop",
        Edited: "Edited",
        "Your video is being cropped, please wait a moment until it is completed.": "Your video is being cropped, please wait a moment until it is completed.",
        "User entry": "User entry",
        "Guest entry": "Guest entry",
        "Entrance record": "Entrance",
        "Owner approval required": "Owner approval required",
        "AirCampus user approval required": "AirCampus user approval required",
        "If you know the URL you can enter": "If you know the URL you can enter",
        "Discontinue a breakout session": "Discontinue a breakout session",
        "Create a summary of the recording and send it via email.": "Create a summary of the recording and send it via email.",
        "Recording file": "Recording file",
        "Recording date": "Recording date",
        "Recording time": "Recording time",
        Transcription: "Transcription",
        "AI Summary": "AI Summary",
        Operation: "Operation",
        Generate: "Generate",
        Sharing: "Sharing",
        "Original file": "Original file",
        "There is 1 minute left in your breakout rooms.": "There is 1 minute left in your breakout rooms.",
        "Brightness adjustment": "Brightness adjustment",
        "Display vertically": "Display vertically",
        "Display horizontal": "Display horizontal",
        "Session Admin Settings": "Admin Settings",
        "Do you want to delete it?": "Do you want to delete it?",
        Name: "Name",
        "Registered Date": "Registered Date",
        "Account already exists": "Account already exists",
        "Logged in as": "Logged in as {0}",
        "Super Admin": "Super Admin",
        "Session Admin": "Session Admin",
        "Share computer audio": "Share computer audio",
        "Stop transcription": "Stop transcription",
        "Send a reaction": "Send a reaction",
        "name sent a reaction": "{0} sent a reaction.",
        "AirCampus Session can't use your camera": "AirCampus Session can't use your camera",
        "Close other apps that might be using your camera": "Close other apps that might be using your camera",
        "If using an external camera, disconnect and reconnect it": "If using an external camera, disconnect and reconnect it",
        "Close and reopen your browser": "Close and reopen your browser",
        "Try again": "Try again",
        "AirCampus Session can't use your mic": "AirCampus Session can't use your mic",
        "If using an external mic, disconnect and reconnect it": "If using an external mic, disconnect and reconnect it",
        "Remove from the Session Room": "Remove {0} from the Session Room",
        "Are you sure you want to kick out of the Session Room?": "Are you sure you want to kick {0} out of the Session Room?",
        "Subtitle setting": "Subtitle setting",
        Language: "Language",
        "Subtitle translation": "Subtitle translation",
        Show: "Show",
        Hide: "Hide",
        "Please select your translation language": "Please select your translation language",
        "Subtitle distribution channel URL": "Subtitle distribution channel URL",
        "under construction": "…",
        "Subtitle Translation": "Subtitle Translation",
        "Other Settings": "Other Settings",
        "Do not display the original text before translation": "Do not display the original text before translation",
        "Stop auto-scrolling": "Stop auto-scrolling",
        "Open subtitle channel": "Open subtitle channel",
        "The language you speak": "The language you speak",
        "Display the speaker name": "Display the speaker name",
        "The speaker language has been changed": "The speaker language has been changed.",
        "Translate the translated sentences": "Translate the translated sentences",
        "Size setting of subtitles": "Size setting of subtitles",
        Small: "Small",
        Standard: "Standard",
        Big: "Big",
        "Choose the language you want to translate": "Choose the language you want to translate",
        "If you do not wish to translate, simply press the close button.": "If you do not wish to translate, simply press the close button.",
        "Network Status": "Network Status",
        "no network": "no network",
        "Stop XX screen sharing": "Stop {0} screen sharing",
        "XX screen sharing will now stop. Are you sure?": "{0} screen sharing will now stop. Are you sure?",
        All: "All",
        "Recording sharing settings": "Recording sharing settings",
        Recording: "Recording",
        "Share Recordings": "Share Recordings",
        "Choose who you want to share your recording with": "Choose who you want to share your recording with",
        "Allow your browser to access your camera": "Allow your browser to access your camera",
        "Allow your browser to access your microphone": "Allow your browser to access your microphone",
        "Please try one of the following methods:": "Please try one of the following methods:",
        "Translation settings": "Translation settings",
        "Download subtitles": "Download subtitles",
        "Please wait a moment before entering the room.": "Please wait a moment before entering the room.",
        "You have left X.": "You have left {0}."
    }
};

export default en;
