import "./index.css";
import Layout from "../layout";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {IconButton, Table, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment/moment";
import {StyledTableCell} from "../../../components/styled/Table";
import {NETWORK_STATUS} from "../../../constants/constant";
import {HtmlTooltip} from "../../../components/icon/icon";

interface IAccessLog {
    id: number
    channel_id: string
    channel_name: string
    client_id: string
    connection_id: string
    name: string
    session_id: string
    bundle_id: string
    network_status: [
        {
            status: number
            time: string
        }
    ]
    created_time: string
    destroyed_time: string | null
}

export default function AccessLog () {
    const [searchParams] = useSearchParams();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const [accessLog, setAccessLog] = useState<IAccessLog[]>();

    const channelId = searchParams.get("channel_id");

    useEffect(() => {
        if (channelId) {
            getAccessLog(channelId).then((data) => {
                setAccessLog(data);
            }).catch(() => {
                enqueueSnackbar(t("Error call list access log"), {variant: "error"});
            });
        }
    }, [channelId]);

    const getAccessLog = async (channelId: string) => {
        const response = await axios.get(`${process.env.REACT_APP_SESSION_API || ""}/room_access_log?channel_id=${channelId}&u=${userId || ""}&a=${authToken || ""}`);
        return response.data.room_access_log;
    };

    const isAccountId = (clientId: string) => {
        return clientId.length === 8 && clientId.charAt(0).match(/[a-zA-Z]/);
    };

    return <Layout>
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>{t("room name")}</StyledTableCell>
                        <StyledTableCell>{t("Room ID")}</StyledTableCell>
                        <StyledTableCell>{t("Session ID")}</StyledTableCell>
                        <StyledTableCell width="100px">{t("Account ID")}</StyledTableCell>
                        <StyledTableCell>{t("Account Name")}</StyledTableCell>
                        <StyledTableCell>{t("Network Status")}</StyledTableCell>
                        <StyledTableCell>{t("Created Time")}</StyledTableCell>
                        <StyledTableCell>{t("Destroyed Time")}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { accessLog?.map((log) => (
                        <TableRow
                            key={log.connection_id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <StyledTableCell align="left">{log.channel_name}</StyledTableCell>
                            <StyledTableCell align="left"><span className="td-uuid">{log.channel_id}</span></StyledTableCell>
                            <StyledTableCell align="left"><span className="td-uuid">{log.session_id}</span></StyledTableCell>
                            <StyledTableCell align="left">{isAccountId(log.client_id) ? log.client_id : "Guest" }</StyledTableCell>
                            <StyledTableCell align="left">{log.name} {log.bundle_id === "share-screen" ? "Share screen" : ""}</StyledTableCell>
                            <StyledTableCell align="center" className="network-status-cell">
                                {
                                    log.network_status.length > 0 &&
                                    <HtmlTooltip title={
                                        log.network_status.map((network, index) => {
                                            return <span style={{ display: "block" }} key={index}>{moment(network.time).format("YYYY-MM-DD HH:mm:ss")} - {t(NETWORK_STATUS.get(network.status))}</span>;
                                        })
                                    }>
                                        <IconButton>
                                            <img className="ac-icon-record" src="/img/article_20dp.svg" alt="article"/>
                                        </IconButton>
                                    </HtmlTooltip>
                                }
                            </StyledTableCell>
                            <StyledTableCell align="left">{moment(log.created_time).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                            <StyledTableCell align="left">{log.destroyed_time && moment(log.destroyed_time).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Layout>;
}
