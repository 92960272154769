/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessages = /* GraphQL */ `
  query GetMessages($channel: String!, $sessionId: String!) {
    getMessages(channel: $channel, sessionId: $sessionId) {
      content
      connectionId
      channel
      resultsId
      language
      isFinal
      sessionId
      name
      created_at
      __typename
    }
  }
`;
