import UserFrame from "../userFrame/userFrame";
import "./meeting.css";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import React, {type CSSProperties, useEffect, useRef, useState} from "react";
import OtherUsers from "../otherUsers/otherUsers";
import {
    AVATAR_DEFAULT,
    MAX_REMOTE_CONNECTION_SHOW,
    ONE_HUNDREDTH_FIVE,
    ONE_HUNDREDTH_FOUR,
    ONE_HUNDREDTH_SIX,
    ONE_HUNDREDTH_THIRD,
    ONE_HUNDREDTH_TWO,
    RECVONLY,
    REMOTE_CONNECTION_SHOW
} from "../../constants/constant";
import {
    type RemoteConnection, setDimensionsListUserSharing,
    setListUserHorizontal,
    setShowListUser
} from "../../services/sora/soraSlice";
import {handleImageError, stringFormat} from "../../utils/utils";
import TimerSetting from "../timerSetting/timerSetting";
import {useTranslation} from "react-i18next";
import {IconButton} from "@mui/material";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
import {HtmlTooltip} from "../icon/icon";

export type ListUserGird = Record<number, UserGird[]>;

interface UserGird {
    length: number
    width: number
}

const LIST_USER_GIRD: ListUserGird = {
    1: [{length: 1, width: 100}],
    2: [{length: 2, width: 100}],
    3: [{length: 2, width: ONE_HUNDREDTH_TWO}, {length: 1, width: ONE_HUNDREDTH_TWO}],
    4: [{length: 2, width: ONE_HUNDREDTH_TWO}, {length: 2, width: ONE_HUNDREDTH_TWO}],
    5: [{length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 2, width: ONE_HUNDREDTH_TWO}],
    6: [{length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 3, width: ONE_HUNDREDTH_THIRD}],
    7: [{length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 1, width: ONE_HUNDREDTH_THIRD}],
    8: [{length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 2, width: ONE_HUNDREDTH_THIRD}],
    9: [{length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 3, width: ONE_HUNDREDTH_THIRD}],
    10: [{length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 3, width: ONE_HUNDREDTH_THIRD}, {length: 3, width: ONE_HUNDREDTH_THIRD}],
    11: [{length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 3, width: ONE_HUNDREDTH_THIRD}],
    12: [{length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    13: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    14: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    15: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    16: [{length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    17: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    18: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 4, width: ONE_HUNDREDTH_FOUR}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    19: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 4, width: ONE_HUNDREDTH_FOUR}],
    20: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    21: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    22: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    23: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    24: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}],
    25: [{length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    26: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    27: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    28: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    29: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 5, width: ONE_HUNDREDTH_FIVE}],
    30: [{length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}, {length: 6, width: ONE_HUNDREDTH_SIX}]
};

interface MeetingProps {
    startPinAll?: any
    removePinAll?: any
    stopShare: any
    sendMessageCountdownUpdate: (countdown: number, isClick: boolean, isStopBreakoutRoom?: boolean) => void
}

function Meeting (prop: MeetingProps) {
    const {
        localMediaStream,
        remoteMediaStreams,
        remoteMediaStreamsRemoved,
        remoteConnection,
        isMineShare,
        idConnectShared,
        connectType,
        isListUserHorizontal,
        isShowListUser,
        dimensionsListUserSharing,
        isShowChat,
        isSHowParticipantList,
        isShowBreakoutRoom,
        isSubTitle,
        widthSidebar
    } = useSelector((state: RootState) => state.sora);
    const listStreamsLength = remoteConnection.filter(connection => !connection.isRecvonly)?.length || 1;
    const [shareConnection, setShareConnection] = useState<RemoteConnection | undefined>();
    const listUserRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const isResizingRef = useRef<boolean>(false);
    const dimensionsRef = useRef<number>(90);

    const getShareStream = (connection: RemoteConnection): MediaStream | undefined => {
        if (localMediaStream && connection.mediaStreamId === localMediaStream?.id) {
            return localMediaStream;
        } else {
            const mediaStream = remoteMediaStreams.find(item => item.id === connection.mediaStreamId);
            if (mediaStream) {
                return mediaStream;
            }
        }
        return undefined;
    };

    useEffect(() => {
        const shareConnection = remoteConnection.find(item => item.isPin);
        if (shareConnection) {
            setShareConnection(shareConnection);
        } else {
            setShareConnection(undefined);
        }
    }, [remoteConnection]);

    const getScreenSharing = (shareConnection: RemoteConnection) => {
        const isYourShare = isMineShare && shareConnection.mediaStreamId === idConnectShared && shareConnection.isShareScreen;
        return <div className="screen-sharing">
            <span className="btn-member-outline" onClick={() => {
                dispatch(setShowListUser(!isShowListUser));
            }} style={btnMemberOutlineStyle()}>
                <img src="/img/ac-member-outline.svg" alt="member-outline"/>
            </span>
            <div className="share-header">
                <div className="share-name">
                    <img className={!isYourShare ? "is-avatar" : ""}
                         src={isYourShare ? "/img/ac-screen-sharing.svg" : (shareConnection.imageUrl ? shareConnection.imageUrl : AVATAR_DEFAULT)}
                         alt="screen sharing" onError={handleImageError}/>
                    <span>{stringFormat(t("Screen is shared with everyone"), shareConnection.realName)}</span>
                </div>
                <span hidden={!isYourShare}
                      className="stop-share-btn" onClick={prop.stopShare}>{t("stop-screen-sharing")}</span>
            </div>
            <UserFrame mediaStream={getShareStream(shareConnection)}
                       connection={shareConnection}
                       startPinAll={prop.startPinAll}
                       removePinAll={prop.removePinAll}
                       index={remoteConnection.findIndex(item => item.mediaStreamId === shareConnection?.mediaStreamId)}/>
        </div>;
    };

    const handleClickBtnScroll = (scroll: number) => {
        if (listUserRef?.current) {
            if (isListUserHorizontal) {
                listUserRef.current.scrollLeft += scroll;
            } else {
                listUserRef.current.scrollTop += scroll;
            }
        }
    };

    const renderMediaStream = () => {
        const mediaStreamList: MediaStream[] = [];
        if (localMediaStream && connectType !== RECVONLY) {
            mediaStreamList.push(localMediaStream);
        }
        mediaStreamList.push(...remoteMediaStreams
            .filter(userMediaStream => !remoteMediaStreamsRemoved
                .some(idStreamsRemoved => idStreamsRemoved === userMediaStream.id)));

        const listUserGird = LIST_USER_GIRD[listStreamsLength > MAX_REMOTE_CONNECTION_SHOW ? MAX_REMOTE_CONNECTION_SHOW : listStreamsLength];

        const mediaStreamRender: React.ReactElement[] = [];
        let height = `calc(100% / ${listUserGird?.length || 1})`;

        mediaStreamList.forEach((mediaStream, index) => {
            const userGird = getUserGirdByIndex(index, listUserGird);
            let width = "0";
            if (userGird) {
                width = `${userGird.width}%`;
            }
            if (index === REMOTE_CONNECTION_SHOW && listStreamsLength > MAX_REMOTE_CONNECTION_SHOW) {
                width = "0";
                height = "0";
            }
            mediaStreamRender.push(<UserFrame key={index}
                                              index={index}
                                              mediaStream={mediaStream}
                                              connection={remoteConnection.find(connection => connection.mediaStreamId === mediaStream.id)}
                                              startPinAll={prop.startPinAll}
                                              removePinAll={prop.removePinAll}
                                              height={height}
                                              width={width}/>);
        });

        return mediaStreamRender;
    };

    const getUserGirdByIndex = (index: number, userGirdList: UserGird[]) => {
        let length = 0;
        for (const userGird of userGirdList) {
            length += userGird?.length || 0;
            if (index < length) {
                return userGird;
            }
        }
        return null;
    };

    const handleCLickChangeLayout = () => {
        let dimensionsListUserSharing = 90;
        if (isListUserHorizontal) {
            dimensionsListUserSharing = 200;
        }
        dispatch(setDimensionsListUserSharing(dimensionsListUserSharing));
        dispatch(setListUserHorizontal(!isListUserHorizontal));
    };

    const listUserListDisplayStyle = () => {
        if (!shareConnection) return {};
        if (!isShowListUser) return {};
        let style: CSSProperties = {
            width: dimensionsListUserSharing
        };
        if (isListUserHorizontal) {
            style = {
                height: dimensionsListUserSharing
            };
        }
        return style;
    };

    const btnMemberOutlineStyle = () => {
        if (!shareConnection) return {};
        if (!isShowListUser) return {};
        if (isListUserHorizontal) {
            return {
                top: dimensionsListUserSharing + 50
            };
        }
        return {
            right: dimensionsListUserSharing
        };
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        isResizingRef.current = true;
    };

    const handleMouseMove = (e: MouseEvent): void => {
        e.preventDefault();
        if (isResizingRef.current) {
            if (isListUserHorizontal) {
                let newDimensions = e.clientY - 50;
                if (newDimensions < 90) {
                    newDimensions = 90;
                }
                if (newDimensions > 200) {
                    newDimensions = 200;
                }
                dimensionsRef.current = newDimensions;
                dispatch(setDimensionsListUserSharing(newDimensions));
            } else {
                let newDimensions = window.innerWidth - e.clientX;
                if (isNarrow()) {
                    newDimensions = newDimensions - widthSidebar;
                }
                if (newDimensions < 175) {
                    newDimensions = 175;
                }
                if (newDimensions > 300) {
                    newDimensions = 300;
                }
                dimensionsRef.current = newDimensions;
                dispatch(setDimensionsListUserSharing(newDimensions));
            }
        }
    };

    const handleMouseUp = () => {
        isResizingRef.current = false;
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isListUserHorizontal, isShowChat, isSHowParticipantList, isShowBreakoutRoom, isSubTitle, widthSidebar]);

    const isNarrow = () => {
        return isShowChat || isSHowParticipantList || isShowBreakoutRoom || isSubTitle;
    };

    return <div className={`container-meeting-display ${isShowListUser ? "show-list-user-sharing" : ""} ${isListUserHorizontal ? "list-user-display-horizontal" : ""}`}>
        {
            (shareConnection && getShareStream(shareConnection)) && getScreenSharing(shareConnection)
        }
        <div className={`list-user-display ${shareConnection ? "is-screen-sharing" : ""}`} style={listUserListDisplayStyle()}>
            <HtmlTooltip placement="left"
                         title={isListUserHorizontal ? t("Display vertically") : t("Display horizontal")}>
                <IconButton className="icon-change-layout" onClick={handleCLickChangeLayout}>
                    <ViewSidebarOutlinedIcon style={{color: "#ffffff"}}/>
                </IconButton>
            </HtmlTooltip>
            <div className="list-user-scrollbar">
                <span className="btn-scrollbar-list btn-scrollbar-top" onClick={() => {
                    handleClickBtnScroll(-100);
                }}>
                    <img src="/img/ci-arrow-up.svg" alt="ExpandMoreIcon"/>
                </span>
                <span className="btn-scrollbar-list btn-scrollbar-bottom" onClick={() => {
                    handleClickBtnScroll(+100);
                }}>
                    <img src="/img/ci-arrow-down.svg" alt="ExpandMoreIcon"/>
                </span>
                <div ref={listUserRef}
                     className={`list-user length-${listStreamsLength} ${listStreamsLength > MAX_REMOTE_CONNECTION_SHOW ? "more-than-13-people" : ""}`}>
                    {
                        renderMediaStream()
                    }
                    {
                        (listStreamsLength > MAX_REMOTE_CONNECTION_SHOW && !shareConnection) &&
                        <OtherUsers remoteConnection={remoteConnection}/>
                    }
                </div>
            </div>
            <div className="list-user-handle-width" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}/>
        </div>
        <TimerSetting sendMessageCountdownUpdate={prop.sendMessageCountdownUpdate}/>
    </div>;
}

export default Meeting;
