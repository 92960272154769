import {createAsyncThunk, createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {IS_NOISE_SUPPRESSION, IS_VIRTUAL, VIRTUAL_BACKGROUND_IMG_LG} from "../../constants/constant";
import {type VirtualBackgroundProcessor} from "@shiguredo/virtual-background";
import {BackgroundImage, Blur} from "../../components/modals/virtualBackground/virtualBackgroundModal";
import axios from "axios";
import {getCookieByName} from "../../utils/CookieUtil";
import {type NoiseSuppressionProcessor} from "@shiguredo/noise-suppression";

export interface VirtualBackgroundState {
    processor: VirtualBackgroundProcessor | null
    blurRadius: number
    backgroundImage: string | undefined
    isShowVirtualBackgroundModal: boolean
    backgroundUploadList: string[]
    isNoiseSuppression: boolean
    noiseSuppressionProcessor: NoiseSuppressionProcessor | null
}

const virtual = localStorage.getItem(IS_VIRTUAL);

const checkBackgroundImage = (virtual: string | null) => {
    if (virtual && !Number.isInteger(Number(virtual))) {
        const backgroundImageValue = Object.values(BackgroundImage).map(value => {
            return `${VIRTUAL_BACKGROUND_IMG_LG}${value}`;
        });
        if (backgroundImageValue.includes(virtual)) {
            return virtual;
        }
    }
    return undefined;
};

export const getBackgroundUploadList = createAsyncThunk(
    "GetBackgroundUploadList",
    async () => {
        const userId = getCookieByName("u");
        const authToken = getCookieByName("a");
        if (userId && authToken) {
            const response = await axios.get(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/background_image?a=${authToken}&u=${userId}`);
            if (response?.data?.data?.background_image?.length > 0) {
                return response.data.data.background_image.map((item: {url: string}) => item.url);
            }
        }

        return [];
    }
);

const isNoiseSuppression = localStorage.getItem(IS_NOISE_SUPPRESSION) === "true";

const initialState: VirtualBackgroundState = {
    processor: null,
    blurRadius: virtual && Number(virtual) <= 100 && Number(virtual) >= 0 ? Number(virtual) : Number(Blur.BLUR_15),
    backgroundImage: checkBackgroundImage(virtual),
    isShowVirtualBackgroundModal: false,
    backgroundUploadList: [],
    isNoiseSuppression,
    noiseSuppressionProcessor: null
};

export const virtualBackgroundSlice = createSlice({
    name: "virtualBackground",
    initialState,
    reducers: {
        setProcessor: (state, action: PayloadAction<VirtualBackgroundProcessor>) => {
            state.processor = action.payload;
        },
        setBlurVirtualBackground: (state, action: PayloadAction<number>) => {
            state.blurRadius = action.payload;
            state.backgroundImage = undefined;
        },
        setBackgroundImageVirtualBackground: (state, action: PayloadAction<string>) => {
            state.backgroundImage = action.payload;
            state.blurRadius = 0;
        },
        setShowVirtualBackgroundModal: (state, action: PayloadAction<boolean>) => {
            state.isShowVirtualBackgroundModal = action.payload;
        },
        addBackgroundUpload: (state, action: PayloadAction<string>) => {
            state.backgroundUploadList.push(action.payload);
        },
        removeBackgroundUploadItem: (state, action: PayloadAction<string>) => {
            const index = state.backgroundUploadList.indexOf(action.payload);
            if (index !== -1) {
                state.backgroundUploadList.splice(index, 1);
            }
        },
        setNoiseSuppressionProcessor: (state, action: PayloadAction<NoiseSuppressionProcessor>) => {
            state.noiseSuppressionProcessor = action.payload;
        },
        setIsNoiseSuppression: (state, action: PayloadAction<boolean>) => {
            state.isNoiseSuppression = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBackgroundUploadList.fulfilled, (state, action) => {
            state.backgroundUploadList = action.payload;
            if (virtual && !Number.isInteger(Number(virtual)) && action.payload.includes(virtual)) {
                state.backgroundImage = virtual;
            }
        });
    }
});

export const {
    setProcessor,
    setBlurVirtualBackground,
    setBackgroundImageVirtualBackground,
    setShowVirtualBackgroundModal,
    addBackgroundUpload,
    removeBackgroundUploadItem,
    setNoiseSuppressionProcessor,
    setIsNoiseSuppression
} = virtualBackgroundSlice.actions;

export default virtualBackgroundSlice.reducer;
