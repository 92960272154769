import type {PayloadAction} from "@reduxjs/toolkit";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    IS_CAMERA,
    IS_MIC,
    LIGHT_ADJUSTMENT,
    MIRRORING_WEBCAM,
    SCREEN_SHARE_AUDIO,
    SENDRECV,
    STRENGTH_LIGHT_ADJUSTMENT
} from "../../constants/constant";
import axios from "axios";
import {type MessageChat} from "../type/chatType";
import {type RootState} from "../../store";
import {type MessageOnOffMicCamera} from "../../components/userFrame/userFrame";
import type {
    CheckboxOwner,
    GetSessionResponse,
    RecordingData,
    ResetDataMeetingPayload,
    Room,
    RoomDetail
} from "../../types/types";
import {fetchListRooms, getRoom} from "./soraApi";
import {type SnackbarKey} from "notistack";
import {type LightAdjustmentGpuProcessor} from "@shiguredo/light-adjustment-gpu/dist/light_adjustment_gpu";
import {languages} from "../../components/subTitle/subTitleDialog";
import {getCookieByName} from "../../utils/CookieUtil";
import moment from "moment";

export interface RemoteConnection {
    name: string | undefined
    imageUrl: string | undefined
    isShareScreen: boolean
    // local ConectionId
    mediaStreamId: string | undefined
    isPin: boolean
    isMine: boolean
    realName: string
    userId: string | null
    speakingTime: number
    isMeetingOwner: boolean
    isRecvonly: boolean
    networkStatus: number | null
    // remote ConnectionId
    connectionId: string | undefined
    allow_user_access: number | null
    allow_guest_access: number | null
    isMirroringWebcam?: boolean
    breakoutRoom?: string
}

export interface Particles {
    isShow: boolean
    emojiType?: string
    name?: string
    emojiQuantity?: number
}

export enum ParticlesType {
    SNOW = "snow",
    FIREWORKS = "fireworks",
    EMOJI = "emoji",
    NONE = "none"
}

export interface SpeakingTime {
    connectionId: string
    speakingTime: number
}

export interface ContentSubTitle {
    name: string | null
    content: string | null
}

export interface Metadata {
    name: string
    imageUrl: string
    isShareScreen: boolean
    userId: string
    isMeetingOwner: boolean
    isRecvonly: boolean
    network: number | null
    networkStatus: number | null
    speakingTime: number
    allow_user_access: number | null
    allow_guest_access: number | null
    isConnectSoraOwnerJoinBreakoutRoom: boolean
    breakout_room?: string
    isMirroringWebcam?: boolean
}

export interface BreakoutRoom {
    channelId: string
    roomName: string
}

export interface DeepGramOptions {
    language: string
    model: string
    smart_format: boolean
    diarize: boolean
    dictation: boolean
    filler_words: boolean
    numerals: boolean
    punctuate: boolean
    profanity_filter: boolean
    interim_results: boolean
}

export interface TranscriptDeepGram {
    resultsId: string
    connectionId: string
    name: string
    transcript: string
    isFinal: boolean
    avatarUrl?: string
    isMine: boolean
    translate?: string
    indexBackground?: number
    translateTranslatedSentences?: string
    language: string
    created_at?: string
}

export interface SoraSliceState {
    connectType: string
    signalingUrlCandidates: string | string[]
    channelId: string
    metadata: any
    remoteMediaStreams: MediaStream[]
    remoteMediaStreamsRemoved: string[]
    isMeeting: boolean
    isCameraOn: boolean
    isMicroOn: boolean
    localMediaStream: MediaStream | null
    isShowChat: boolean
    remoteConnection: RemoteConnection[]
    isMineShare: boolean
    messageOnOffMicCameraList: MessageOnOffMicCamera[]
    isOpenInputNameDialog: boolean
    isRecording: boolean
    idConnectShared: string
    messageChats: MessageChat[]
    connectionIdPin: string | null
    connectionSetPin: RemoteConnection | null
    isSHowParticipantList: boolean
    isShowBreakoutRoom: boolean
    countdownValue: number
    isJoinMeetingButtonDisabled: boolean
    isOpenErrorDevices: boolean
    countdown: number
    nameSetCountdown: string
    snowParticles: Particles
    fireworksParticles: Particles
    emojiParticles: Particles
    isShowParticles: boolean
    showContentChat: MessageChat | null
    textMessage: string
    totalSpeakingTime: number
    firstLogin: boolean
    meetingType: string | null
    toggledSidebarLeft: boolean
    roomName: string
    speakingTimeMine: number
    isSeenMessage: boolean
    isSubTitle: boolean
    contentSubTitle: TranscriptDeepGram[]
    isMeetingOwner: boolean
    mainRoomName: string
    roomDetail: RoomDetail | null
    isShowRoomDetailModal: boolean
    enqueueSnackbarKeyEmptyRoom: SnackbarKey | undefined
    isLightAdjustment: boolean
    lightAdjustmentProcessor: LightAdjustmentGpuProcessor | null
    strengthLightAdjustment: number
    enqueueSnackbarNetworkKey: SnackbarKey | undefined
    myConnectionId: string | undefined
    isMirroringWebcam: boolean
    checkboxOwner: CheckboxOwner | undefined
    breakoutRoom: BreakoutRoom | null
    breakoutRooms: Room[]
    disableClickBreakoutRoom: boolean
    isBreakoutRoomStarted: boolean
    remoteConnectionBreakoutRoomStarted: RemoteConnection[]
    breakoutRoomExpirationTime: string
    isOneMinuteLeftInTheBreakoutRooms: boolean
    isListUserHorizontal: boolean
    objectChat: string
    isScreenShareAudio: boolean
    isErrorCamera: boolean
    isErrorMic: boolean
    isOpenErrorCameraDialog: boolean
    isOpenErrorMicDialog: boolean
    isInitializingStream: boolean
    subtitleLanguage: string
    isShowListUser: boolean
    sessionId: string
    subtitleTranslationLanguage: string
    isHideOriginalText: boolean
    isStopAutoScroll: boolean
    widthSidebar: number
    isDisplaySpeakerName: boolean
    isTranslateTranslatedSentences: boolean
    subtitleFontsize: number
    isOpenSubtitleSetting: boolean
    dimensionsListUserSharing: number
    recordingData: RecordingData | undefined
}

export const getSession = createAsyncThunk(
    "GetSession",
    async (arg, {getState}) => {
        const state = getState() as RootState;
        if (state.sora.breakoutRoom?.channelId) {
            const params = {channel_id: state.sora.breakoutRoom?.channelId};
            try {
                const response = await axios.post<GetSessionResponse>(`${process.env.REACT_APP_WEBRTC_API || ""}`, params, {
                    headers: {
                        "x-sora-target": "Sora_20231220.GetSession"
                    }
                });
                return response.data;
            } catch (err) {
                console.log(err);
                return null;
            }
        }
        return null;
    }
);

export const getListBreakoutRooms = createAsyncThunk(
    "GetListBreakoutRooms",
    async (arg, {getState}) => {
        try {
            const state = getState() as RootState;
            return await fetchListRooms(state.sora.channelId);
        } catch (e) {
            return [];
        }
    }
);

export const getRoomDetail = createAsyncThunk(
    "GetRoomDetail",
    async (arg, {getState}) => {
        const state = getState() as RootState;
        if (state.sora.channelId) {
            try {
                const channelId = state.sora.channelId;
                const response = await getRoom(channelId);
                return response.data.data;
            } catch (e) {
                console.log(e);
                return null;
            }
        }
        return null;
    }
);

export const getRecordingData = createAsyncThunk(
    "GetRecordingData",
    async (arg, {getState}) => {
        const state = getState() as RootState;
        const userId = getCookieByName("u") || "";
        const authToken = getCookieByName("a") || "";
        if (state.sora.channelId && state.sora.sessionId) {
            try {
                const channelId = state.sora.channelId;
                const sessionId = state.sora.sessionId;
                const response = await axios.get<RecordingData>(`${process.env.REACT_APP_SESSION_API || ""}/recording_data?a=${authToken}&u=${userId}&channel_id=${channelId}&session_id=${sessionId}`);
                if (response.status === 200) {
                    return response.data;
                }
            } catch (e) {
                console.log(e);
                return null;
            }
        }
        return null;
    }
);

const isCamera = localStorage.getItem(IS_CAMERA) !== "false";
const isMic = localStorage.getItem(IS_MIC) !== "false";
const mirroringWebcam = localStorage.getItem(MIRRORING_WEBCAM) === "true";
const lightAdjustment = localStorage.getItem(LIGHT_ADJUSTMENT) === "true";
const screenShareAudio = localStorage.getItem(SCREEN_SHARE_AUDIO) !== "false";
const strengthLightAdjustment = Number.parseFloat(localStorage.getItem(STRENGTH_LIGHT_ADJUSTMENT) || "0.5");
const languageCode = navigator.language.split("-")[0];

let language = languages.some(language => language.code === languageCode) ? languageCode : "ja";
const subtitleLanguage = localStorage.getItem("subtitleLanguage");
if (subtitleLanguage) {
    language = subtitleLanguage;
}
const subtitleTranslationLanguage = localStorage.getItem("subtitleTranslationLanguage") || "0";
const subtitleFontsize = Number.parseInt(localStorage.getItem("subtitleFontsize") || "16") || 16;
const isHideOriginalText = localStorage.getItem("isHideOriginalText") === "true";
const isDisplaySpeakerName = localStorage.getItem("isDisplaySpeakerName") === "true";
const isTranslateTranslatedSentences = localStorage.getItem("isTranslateTranslatedSentences") === "true";
const isStopAutoScroll = localStorage.getItem("isStopAutoScroll") === "true";

const initialState: SoraSliceState = {
    connectType: SENDRECV,
    signalingUrlCandidates: process.env.REACT_APP_WEBRTC_SIGNALING || "",
    channelId: "",
    metadata: {},
    remoteMediaStreams: [],
    remoteMediaStreamsRemoved: [],
    isMeeting: false,
    isCameraOn: isCamera,
    isMicroOn: isMic,
    localMediaStream: null,
    isShowChat: false,
    remoteConnection: [],
    messageOnOffMicCameraList: [],
    isOpenInputNameDialog: false,
    isRecording: false,
    isMineShare: false,
    idConnectShared: "",
    messageChats: [],
    connectionIdPin: null,
    connectionSetPin: null,
    isSHowParticipantList: false,
    isShowBreakoutRoom: false,
    isOpenErrorDevices: false,
    countdownValue: 0,
    isJoinMeetingButtonDisabled: false,
    countdown: 0,
    nameSetCountdown: "",
    snowParticles: {
        isShow: false
    },
    fireworksParticles: {
        isShow: false
    },
    emojiParticles: {
        isShow: false
    },
    isShowParticles: false,
    showContentChat: null,
    textMessage: "",
    totalSpeakingTime: 0,
    firstLogin: false,
    meetingType: null,
    toggledSidebarLeft: false,
    roomName: "",
    speakingTimeMine: 0,
    isSeenMessage: true,
    isSubTitle: false,
    contentSubTitle: [],
    isMeetingOwner: false,
    mainRoomName: "",
    roomDetail: null,
    isShowRoomDetailModal: false,
    enqueueSnackbarKeyEmptyRoom: undefined,
    isLightAdjustment: lightAdjustment,
    lightAdjustmentProcessor: null,
    strengthLightAdjustment,
    enqueueSnackbarNetworkKey: undefined,
    myConnectionId: undefined,
    isMirroringWebcam: mirroringWebcam,
    checkboxOwner: undefined,
    breakoutRoom: null,
    breakoutRooms: [],
    disableClickBreakoutRoom: false,
    isBreakoutRoomStarted: false,
    remoteConnectionBreakoutRoomStarted: [],
    breakoutRoomExpirationTime: "15:00",
    isOneMinuteLeftInTheBreakoutRooms: false,
    isListUserHorizontal: true,
    objectChat: "all",
    isScreenShareAudio: screenShareAudio,
    isErrorCamera: false,
    isErrorMic: false,
    isOpenErrorCameraDialog: false,
    isOpenErrorMicDialog: false,
    isInitializingStream: true,
    subtitleLanguage: language,
    isShowListUser: false,
    sessionId: "",
    subtitleTranslationLanguage,
    isHideOriginalText,
    isStopAutoScroll,
    widthSidebar: 350,
    isDisplaySpeakerName,
    isTranslateTranslatedSentences,
    subtitleFontsize,
    isOpenSubtitleSetting: false,
    dimensionsListUserSharing: 90,
    recordingData: undefined
};
export const soraSlice = createSlice({
    name: "sora",
    initialState,
    reducers: {
        setConnectType: (state, action: PayloadAction<string>) => {
            state.connectType = action.payload;
        },
        addRemoteMediaStream: (state, action: PayloadAction<MediaStream>) => {
            if (!state.remoteMediaStreams.some(item => item.id === action.payload.id)) {
                state.remoteMediaStreams.push(action.payload);
            }
        },
        removeRemoteMediaStream: (state, action: PayloadAction<string>) => {
            state.remoteMediaStreamsRemoved.push(action.payload);
        },
        setMeeting: (state, action: PayloadAction<boolean>) => {
            state.isMeeting = action.payload;
        },
        userMediaStreamListEmpty: (state) => {
            state.remoteMediaStreams = [];
        },
        setCameraOn: (state, action: PayloadAction<boolean>) => {
            state.isCameraOn = action.payload;
        },
        setMicroOn: (state, action: PayloadAction<boolean>) => {
            state.isMicroOn = action.payload;
        },
        setLocalMediaStream: (state, action: PayloadAction<MediaStream>) => {
            if (state.localMediaStream) {
                state.localMediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
            }
            if (state.remoteConnection) {
                state.remoteConnection.forEach(connection => {
                    if (connection.isMine) {
                        connection.mediaStreamId = action.payload.id;
                    }
                });
            }
            for (const track of action.payload.getAudioTracks()) {
                track.enabled = state.isMicroOn;
            }
            state.localMediaStream = action.payload;
        },
        updateAudioTracks: (state, action: PayloadAction<MediaStreamAudioTrack | null>) => {
            if (state.localMediaStream) {
                const localMediaStream = new MediaStream(state.localMediaStream);
                if (action.payload) {
                    localMediaStream.getAudioTracks().forEach((track) => {
                        track.stop();
                        localMediaStream.removeTrack(track);
                    });
                    localMediaStream.addTrack(action.payload);
                } else {
                    localMediaStream.getAudioTracks().forEach((track) => {
                        track.stop();
                        localMediaStream.removeTrack(track);
                    });
                }
                if (state.remoteConnection) {
                    state.remoteConnection.forEach(connection => {
                        if (connection.isMine) {
                            connection.mediaStreamId = localMediaStream.id;
                        }
                    });
                }
                state.localMediaStream = localMediaStream;
            }
        },
        setShowChat: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.isSHowParticipantList = false;
                state.isShowBreakoutRoom = false;
            }
            state.isShowChat = action.payload;
        },
        addRemoteConnection: (state, action: PayloadAction<RemoteConnection>) => {
            state.remoteConnection.push(action.payload);
        },
        removeRemoteConnection: (state, action: PayloadAction<string>) => {
            state.remoteConnection = state.remoteConnection.filter(connection => connection.mediaStreamId !== action.payload);
        },
        updateRemoteConnection: (state, action: PayloadAction<RemoteConnection>) => {
            state.remoteConnection = state.remoteConnection.map(connection => {
                if (connection.mediaStreamId === action.payload.mediaStreamId) {
                    return action.payload;
                }
                return connection;
            });
        },
        addMessageOnOffMicCameraList: (state, action: PayloadAction<MessageOnOffMicCamera>) => {
            if (state.messageOnOffMicCameraList.some(messageOnOffMicCamera => messageOnOffMicCamera.connectionId === action.payload.connectionId)) {
                state.messageOnOffMicCameraList = state.messageOnOffMicCameraList
                    .map(messageOnOffMicCamera => messageOnOffMicCamera.connectionId === action.payload.connectionId ? action.payload : messageOnOffMicCamera);
            } else {
                state.messageOnOffMicCameraList.push(action.payload);
            }
        },
        setChannelId: (state, action: PayloadAction<string>) => {
            state.channelId = action.payload;
        },
        setMetadata: (state, action: PayloadAction<object>) => {
            state.metadata = action.payload;
        },
        updateMetadata: (state, action: PayloadAction<object>) => {
            state.metadata = {
                ...state.metadata,
                ...action.payload
            };
        },
        setOpenInputNameDialog: (state, action: PayloadAction<boolean>) => {
            state.isOpenInputNameDialog = action.payload;
        },
        setRecording: (state, action: PayloadAction<boolean>) => {
            state.isRecording = action.payload;
        },
        setIsMineShare: (state, action: PayloadAction<boolean>) => {
            state.isMineShare = action.payload;
        },
        setIsPin: (state, action: PayloadAction<string>) => {
            if (!state.remoteConnection.some(connection => connection.isPin)) {
                state.remoteConnection.map(connection => {
                    if (connection.mediaStreamId === action.payload) {
                        connection.isPin = true;
                    }
                    return connection;
                });
            }
        },
        removeIsPin: (state, action: PayloadAction<string>) => {
            state.connectionIdPin = null;
            state.remoteConnection.map(connection => {
                if (connection.mediaStreamId === action.payload) {
                    connection.isPin = false;
                }
                return connection;
            });
        },
        removeAllIsPin: (state) => {
            state.connectionIdPin = null;
            state.remoteConnection.map(connection => {
                connection.isPin = false;
                return connection;
            });
        },
        setIdConnectShared: (state, action: PayloadAction<string>) => {
            state.idConnectShared = action.payload;
        },
        setMessageChats: (state, action: PayloadAction<MessageChat>) => {
            state.messageChats.push(action.payload);
        },
        setConnectionIdPin: (state, action: PayloadAction<string>) => {
            state.connectionIdPin = action.payload;
        },
        setConnectionSetPin: (state, action: PayloadAction<RemoteConnection | null>) => {
            state.connectionSetPin = action.payload;
        },
        setSHowParticipantList: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.isShowChat = false;
                state.isShowBreakoutRoom = false;
            }
            state.isSHowParticipantList = action.payload;
        },
        setShowBreakoutRoom: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.isShowChat = false;
                state.isSHowParticipantList = false;
            }
            state.isShowBreakoutRoom = action.payload;
        },
        setCountdownValue: (state, action: PayloadAction<number>) => {
            state.countdownValue = action.payload;
        },
        setJoinMeetingButtonDisabled: (state, action: PayloadAction<boolean>) => {
            state.isJoinMeetingButtonDisabled = action.payload;
        },
        setIsOpenErrorDevices: (state, action: PayloadAction<boolean>) => {
            state.isOpenErrorDevices = action.payload;
        },
        setCountdown: (state, action: PayloadAction<number>) => {
            state.countdown = action.payload;
        },
        setNameSetCountdown: (state, action: PayloadAction<string>) => {
            state.nameSetCountdown = action.payload;
        },
        setSnowParticles: (state, action: PayloadAction<Particles>) => {
            state.snowParticles = action.payload;
        },
        setFireworksParticles: (state, action: PayloadAction<Particles>) => {
            state.fireworksParticles = action.payload;
        },
        setEmojiParticles: (state, action: PayloadAction<Particles>) => {
            state.emojiParticles = action.payload;
        },
        setShowParticles: (state, action: PayloadAction<boolean>) => {
            state.isShowParticles = action.payload;
        },
        setShowContentChat: (state, action: PayloadAction<MessageChat | null>) => {
            state.showContentChat = action.payload;
        },
        updateSpeakingTime: (state, action: PayloadAction<string>) => {
            const connection = state.remoteConnection.find(connection => connection.mediaStreamId === action.payload);
            if (connection) {
                connection.speakingTime += 1;
            }
        },
        setSpeakingTime: (state, action: PayloadAction<SpeakingTime>) => {
            const connection = state.remoteConnection.find(connection => connection.connectionId === action.payload.connectionId);
            if (connection) {
                connection.speakingTime = action.payload.speakingTime;
            }
        },
        setTextMessage: (state, action: PayloadAction<string>) => {
            state.textMessage = action.payload;
        },
        setFirstLogin: (state, action: PayloadAction<boolean>) => {
            state.firstLogin = action.payload;
        },
        setMeetingType: (state, action: PayloadAction<string | null>) => {
            state.meetingType = action.payload;
        },
        setToggledSidebarLeft: (state, action: PayloadAction<boolean>) => {
            state.toggledSidebarLeft = action.payload;
        },
        setRoomName: (state, action: PayloadAction<string>) => {
            state.roomName = action.payload;
        },
        updateSpeakingTimeMine: (state) => {
            state.speakingTimeMine = ++state.speakingTimeMine;
        },
        setIsSeenMessage: (state, action: PayloadAction<boolean>) => {
            state.isSeenMessage = action.payload;
        },
        setIsSubTitle: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.isShowChat = false;
                state.isSHowParticipantList = false;
                state.isShowBreakoutRoom = false;
            }
            state.isSubTitle = action.payload;
        },
        setContentSubTitle: (state, action: PayloadAction<TranscriptDeepGram>) => {
            const subTitle = state.contentSubTitle.find(item => item.resultsId === action.payload.resultsId);
            if (subTitle) {
                subTitle.transcript = action.payload.transcript;
                subTitle.isFinal = action.payload.isFinal;
                if (action.payload.translate) {
                    subTitle.translate = action.payload.translate;
                }
                if (action.payload.translateTranslatedSentences) {
                    subTitle.translateTranslatedSentences = action.payload.translateTranslatedSentences;
                }
            } else {
                state.contentSubTitle
                    .filter(transcript => transcript.connectionId === action.payload.connectionId)
                    .forEach(transcript => {
                        transcript.isFinal = true;
                    });
                action.payload.created_at = moment().format("YYYY/MM/DD HH:mm:ss");
                state.contentSubTitle.push(action.payload);
            }
        },
        setMeetingOwner: (state, action: PayloadAction<boolean>) => {
            state.isMeetingOwner = action.payload;
        },
        resetDataMeeting: (state, action: PayloadAction<ResetDataMeetingPayload>) => {
            state.remoteMediaStreams = [];
            state.remoteConnection = [];
            state.messageOnOffMicCameraList = [];
            state.remoteMediaStreamsRemoved = [];
            state.speakingTimeMine = 0;
            state.contentSubTitle = [];
            state.isSubTitle = false;
            if (!action.payload.isJoinBreakoutRoom) {
                state.countdownValue = 0;
                state.countdown = 0;
            }
        },
        setShowRoomDetailModal: (state, action: PayloadAction<boolean>) => {
            state.isShowRoomDetailModal = action.payload;
        },
        setEnqueueSnackbarKeyEmptyRoom: (state, action: PayloadAction<SnackbarKey | undefined>) => {
            state.enqueueSnackbarKeyEmptyRoom = action.payload;
        },
        setLightAdjustment: (state, action: PayloadAction<boolean>) => {
            state.isLightAdjustment = action.payload;
        },
        setLightAdjustmentProcessor: (state, action: PayloadAction<LightAdjustmentGpuProcessor>) => {
            state.lightAdjustmentProcessor = action.payload;
        },
        setStrengthLightAdjustment: (state, action: PayloadAction<number>) => {
            state.strengthLightAdjustment = action.payload;
        },
        setEnqueueSnackbarNetworkKey: (state, action: PayloadAction<SnackbarKey | undefined>) => {
            state.enqueueSnackbarNetworkKey = action.payload;
        },
        setMyConnectionId: (state, action: PayloadAction<string | undefined>) => {
            state.myConnectionId = action.payload;
        },
        setMirroringWebcam: (state, action: PayloadAction<boolean>) => {
            state.isMirroringWebcam = action.payload;
        },
        setCheckboxOwner: (state, action: PayloadAction<CheckboxOwner | undefined>) => {
            state.checkboxOwner = action.payload;
        },
        setBreakoutRoom: (state, action: PayloadAction<BreakoutRoom | null>) => {
            state.breakoutRoom = action.payload;
        },
        setBreakoutRooms: (state, action: PayloadAction<Room[]>) => {
            state.breakoutRooms = action.payload;
        },
        setDisableClickBreakoutRoom: (state, action: PayloadAction<boolean>) => {
            state.disableClickBreakoutRoom = action.payload;
        },
        setBreakoutRoomStarted: (state, action: PayloadAction<boolean>) => {
            state.isBreakoutRoomStarted = action.payload;
        },
        setRemoteConnectionBreakoutRoomStarted: (state, action: PayloadAction<RemoteConnection[]>) => {
            state.remoteConnectionBreakoutRoomStarted = action.payload;
        },
        setBreakoutRoomExpirationTime: (state, action: PayloadAction<string>) => {
            state.breakoutRoomExpirationTime = action.payload;
        },
        setOneMinuteLeftInTheBreakoutRooms: (state, action: PayloadAction<boolean>) => {
            state.isOneMinuteLeftInTheBreakoutRooms = action.payload;
        },
        setListUserHorizontal: (state, action: PayloadAction<boolean>) => {
            state.isListUserHorizontal = action.payload;
        },
        setObjectChat: (state, action: PayloadAction<string>) => {
            state.objectChat = action.payload;
        },
        setScreenShareAudio: (state, action: PayloadAction<boolean>) => {
            state.isScreenShareAudio = action.payload;
        },
        setErrorCamera: (state, action: PayloadAction<boolean>) => {
            state.isErrorCamera = action.payload;
        },
        setErrorMic: (state, action: PayloadAction<boolean>) => {
            state.isErrorMic = action.payload;
        },
        setOpenErrorCameraDialog: (state, action: PayloadAction<boolean>) => {
            state.isOpenErrorCameraDialog = action.payload;
        },
        setOpenErrorMicDialog: (state, action: PayloadAction<boolean>) => {
            state.isOpenErrorMicDialog = action.payload;
        },
        setInitializingStream: (state, action: PayloadAction<boolean>) => {
            state.isInitializingStream = action.payload;
        },
        setSubtitleLanguage: (state, action: PayloadAction<string>) => {
            state.subtitleLanguage = action.payload;
            localStorage.setItem("subtitleLanguage", action.payload);
        },
        handleSubtitleLanguageChange: (state) => {
            state.remoteMediaStreams = [];
            state.remoteConnection = [];
            state.messageOnOffMicCameraList = [];
            state.remoteMediaStreamsRemoved = [];
        },
        setShowListUser: (state, action: PayloadAction<boolean>) => {
            state.isShowListUser = action.payload;
        },
        setSpeakingTimeMine: (state, action: PayloadAction<number>) => {
            state.speakingTimeMine = action.payload;
        },
        setSubtitleTranslationLanguage: (state, action: PayloadAction<string>) => {
            state.subtitleTranslationLanguage = action.payload;
            localStorage.setItem("subtitleTranslationLanguage", action.payload);
        },
        setHideOriginalText: (state, action: PayloadAction<boolean>) => {
            state.isHideOriginalText = action.payload;
            localStorage.setItem("isHideOriginalText", String(action.payload));
        },
        setStopAutoScroll: (state, action: PayloadAction<boolean>) => {
            state.isStopAutoScroll = action.payload;
            localStorage.setItem("isStopAutoScroll", String(action.payload));
        },
        setWidthSidebar: (state, action: PayloadAction<number>) => {
            state.widthSidebar = action.payload;
        },
        setDisplaySpeakerName: (state, action: PayloadAction<boolean>) => {
            state.isDisplaySpeakerName = action.payload;
        },
        setTranslateTranslatedSentences: (state, action: PayloadAction<boolean>) => {
            state.isTranslateTranslatedSentences = action.payload;
        },
        setSubtitleFontsize: (state, action: PayloadAction<number>) => {
            state.subtitleFontsize = action.payload;
            localStorage.setItem("subtitleFontsize", action.payload.toString());
        },
        setOpenSubtitleSetting: (state, action: PayloadAction<boolean>) => {
            state.isOpenSubtitleSetting = action.payload;
        },
        setDimensionsListUserSharing: (state, action: PayloadAction<number>) => {
            state.dimensionsListUserSharing = action.payload;
        },
        setRecordingData: (state, action: PayloadAction<RecordingData | undefined>) => {
            state.recordingData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSession.fulfilled, (state, action) => {
            if (action.payload) {
                state.isRecording = !!action.payload.recording;
                state.sessionId = action.payload.session_id;
            }
        }).addCase(getRoomDetail.fulfilled, (state, action) => {
            if (action.payload != null) {
                state.roomName = action.payload.name;
                state.mainRoomName = action.payload.name;
                state.roomDetail = action.payload;
                state.roomDetail.allow_user_pin = action.payload.allow_user_pin || false;
            }
        }).addCase(getListBreakoutRooms.fulfilled, (state, action) => {
            state.breakoutRooms = action.payload;
        }).addCase(getRecordingData.fulfilled, (state, action) => {
            if (action.payload) {
                state.recordingData = action.payload;
            }
        });
    }
});
export const {
    setConnectType,
    addRemoteMediaStream,
    removeRemoteMediaStream,
    setMeeting,
    userMediaStreamListEmpty,
    setCameraOn,
    setMicroOn,
    setLocalMediaStream,
    setChannelId,
    setShowChat,
    addRemoteConnection,
    removeRemoteConnection,
    updateRemoteConnection,
    addMessageOnOffMicCameraList,
    setMetadata,
    setOpenInputNameDialog,
    setIsMineShare,
    setRecording,
    setIsPin,
    removeIsPin,
    removeAllIsPin,
    setIdConnectShared,
    setMessageChats,
    setConnectionIdPin,
    setConnectionSetPin,
    setSHowParticipantList,
    setShowBreakoutRoom,
    updateMetadata,
    setCountdownValue,
    setJoinMeetingButtonDisabled,
    setIsOpenErrorDevices,
    setCountdown,
    setNameSetCountdown,
    updateAudioTracks,
    setSnowParticles,
    setFireworksParticles,
    setEmojiParticles,
    setShowParticles,
    setShowContentChat,
    updateSpeakingTime,
    setTextMessage,
    setSpeakingTime,
    setFirstLogin,
    setMeetingType,
    setToggledSidebarLeft,
    setRoomName,
    updateSpeakingTimeMine,
    setIsSeenMessage,
    setIsSubTitle,
    setContentSubTitle,
    setMeetingOwner,
    resetDataMeeting,
    setShowRoomDetailModal,
    setEnqueueSnackbarKeyEmptyRoom,
    setLightAdjustment,
    setLightAdjustmentProcessor,
    setStrengthLightAdjustment,
    setEnqueueSnackbarNetworkKey,
    setMyConnectionId,
    setMirroringWebcam,
    setCheckboxOwner,
    setBreakoutRoom,
    setBreakoutRooms,
    setDisableClickBreakoutRoom,
    setBreakoutRoomStarted,
    setRemoteConnectionBreakoutRoomStarted,
    setBreakoutRoomExpirationTime,
    setOneMinuteLeftInTheBreakoutRooms,
    setListUserHorizontal,
    setObjectChat,
    setScreenShareAudio,
    setErrorCamera,
    setErrorMic,
    setOpenErrorCameraDialog,
    setOpenErrorMicDialog,
    setInitializingStream,
    setSubtitleLanguage,
    handleSubtitleLanguageChange,
    setShowListUser,
    setSpeakingTimeMine,
    setSubtitleTranslationLanguage,
    setHideOriginalText,
    setStopAutoScroll,
    setWidthSidebar,
    setDisplaySpeakerName,
    setTranslateTranslatedSentences,
    setSubtitleFontsize,
    setOpenSubtitleSetting,
    setDimensionsListUserSharing,
    setRecordingData
} = soraSlice.actions;
export default soraSlice.reducer;
