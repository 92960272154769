import "./subTitle.css";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    type SelectChangeEvent
} from "@mui/material";
import Icon from "../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {setSubtitleTranslationLanguage} from "../../services/sora/soraSlice";
import {useTranslation} from "react-i18next";
import {languages as translationLanguages} from "../../constants/constant";

interface Props {
    open: any
    handleClose: any
}

export const languages = [
    {name: "Japanese", code: "ja"},
    {name: "English", code: "en"},
    {name: "Bulgarian", code: "bg"},
    {name: "Catalan", code: "ca"},
    {name: "Chinese (Mandarin, Simplified)", code: "zh"},
    {name: "Chinese (Mandarin, Traditional)", code: "zh-TW"},
    {name: "Chinese (Cantonese, Traditional)", code: "zh-HK"},
    {name: "Czech", code: "cs"},
    {name: "Danish", code: "da"},
    {name: "Danish", code: "da"},
    {name: "Estonian", code: "et"},
    {name: "Finnish", code: "fi"},
    {name: "Flemish", code: "nl-BE"},
    {name: "French", code: "fr"},
    {name: "German", code: "de"},
    {name: "German (Switzerland)", code: "de-CH"},
    {name: "Greek", code: "el"},
    {name: "Hindi", code: "hi"},
    {name: "Hungarian", code: "hu"},
    {name: "Indonesian", code: "id"},
    {name: "Italian", code: "it"},
    {name: "Korean", code: "ko"},
    {name: "Latvian", code: "lv"},
    {name: "Lithuanian", code: "lt"},
    {name: "Malay", code: "ms"},
    {name: "Multilingual (Spanish + English)", code: "multi"},
    {name: "Norwegian", code: "no"},
    {name: "Polish", code: "pl"},
    {name: "Portuguese", code: "pt"},
    {name: "Romanian", code: "ro"},
    {name: "Russian", code: "ru"},
    {name: "Slovak", code: "sk"},
    {name: "Spanish", code: "es"},
    {name: "Swedish", code: "sv"},
    {name: "Thai", code: "th"},
    {name: "Turkish", code: "tr"},
    {name: "Ukrainian", code: "uk"},
    {name: "Vietnamese", code: "vi"}
];

export default function SubTitleDialog (props: Props) {
    const {subtitleTranslationLanguage} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const handleChangeSubtitleTranslationLanguage = (event: SelectChangeEvent) => {
        dispatch(setSubtitleTranslationLanguage(event.target.value));
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>{t("Subtitle setting")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <label htmlFor="select-translation-language">{t("Choose the language you want to translate")}</label>
            <FormControl margin="dense" size="small" fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="select-translation-language"
                    value={subtitleTranslationLanguage}
                    onChange={handleChangeSubtitleTranslationLanguage}
                >
                    <MenuItem value="0">No translation</MenuItem>
                    {
                        translationLanguages.map((language, index) => {
                            return <MenuItem value={language.language} key={index}>{language.name}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
            <label htmlFor="select-translation-language">{t("If you do not wish to translate, simply press the close button.")}</label>
        </DialogContent>
        <DialogActions>
            <Button type="submit" onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}
