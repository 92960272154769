export const MICRO_ID = "micro_id";
export const CAMERA_ID = "camera_id";
export const SPEAKER_ID = "speaker_id";
export const IS_CAMERA = "is_camera";
export const IS_MIC = "is_mic";
export const IS_VIRTUAL = "is_virtual";
export const REPLACE_URL_TO_TAG_A = /https?:\/\/[^\s<>]+/g;
export const PATH_MEETING = "/:channelId";
export const PATH_FORUM = "/:type/:id";
export const PATH_ADMIN = "/settings/mychannel";
export const PATH_ADMIN_CHANNEL = "/admin/channel";
export const YOU_JP = "あなた";
export const AVATAR_DEFAULT = "/img/ac-user-removebg-preview.png";
export const REMOTE_CONNECTION_SHOW = 29;
export const MAX_REMOTE_CONNECTION_SHOW = 30;
export const CHANNEL_LIMIT = ["gem_test", "bbt_test"];
export const FORUM_TYPE = "forum";
export const COURSE_TYPE = "course";
export const MEETING_TYPE = "mtg";
export const MEETING_TYPE_VALUE = "meeting";
export const ROLE_CONNECT = "role";
export const SENDRECV = "sendrecv";
export const RECVONLY = "recvonly";
export const ONE_HUNDREDTH_TWO = 100 / 2;
export const ONE_HUNDREDTH_THIRD = 100 / 3;
export const ONE_HUNDREDTH_FOUR = 100 / 4;
export const ONE_HUNDREDTH_FIVE = 100 / 5;
export const ONE_HUNDREDTH_SIX = 100 / 6;
export const VIRTUAL_BACKGROUND_IMG_LG = "/virtual-background/img-lg";
export const VIRTUAL_BACKGROUND_IMG_SM = "/virtual-background/img-sm";
export const MESSAGE_NOT_ALLOWED = "Signaling failed. CloseEventCode:4490 CloseEventReason:'Access to the meeting is not allowed'";
export const MESSAGE_NOT_ALLOWED_OVER_30 = "Signaling failed. CloseEventCode:4490 CloseEventReason:'You could not enter the room because the number of people who can enter was exceeded'";
export const HTTP = "http://";
export const HTTPS = "https://";
export const PATH_RECORD = "/settings/record";

export const PATH_ACCESS_LOG = "/settings/access-log";
export const NETWORK_STATUS = new Map([[-1, "no network"], [0, "very stable network"], [1, "stable network"], [2, "unstable network"], [3, "very unstable network"]]);
export const VIDEO_QUALITY_DEFAULT = 360;
export const BACKGROUND_UPLOAD_LIST = "backgroundUploadList";
export const ALLOW_ACCESS_TYPE = {
    not_allow: 0,
    allow: 1,
    owner_allow: 2,
    user_allow: 3
};
export const LOGIN_JOIN_MEETING = "loginJoinMeeting";

export const MAX_MANAGERS_ROOM = 10;
export const PATH_ADMINISTRATOR_SETUP = "/settings/administrator-setup";
export const IS_NOISE_SUPPRESSION = "is_noise_suppression";
export const MIRRORING_WEBCAM = "mirroring_webcam";
export const LIGHT_ADJUSTMENT = "light_adjustment";
export const SCREEN_SHARE_AUDIO = "screen_share_audio";
export const STRENGTH_LIGHT_ADJUSTMENT = "strength_light_adjustment";
export const PERMISSION_DENIED_BY_SYSTEM = ["Permission denied by system", "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."];
export const NOT_ALLOWED_ERROR = "NotAllowedError";
export const languages = [
    {
        language: "en",
        name: "English"
    },
    {
        language: "ja",
        name: "Japanese"
    },
    {
        language: "zh",
        name: "Chinese (Simplified)"
    },
    {
        language: "zh-TW",
        name: "Chinese (Traditional)"
    },
    {
        language: "es",
        name: "Spanish"
    },
    {
        language: "ar",
        name: "Arabic"
    },
    {
        language: "fr",
        name: "French"
    },
    {
        language: "hi",
        name: "Hindi"
    },
    {
        language: "id",
        name: "Indonesian"
    },
    {
        language: "ms",
        name: "Malay"
    },
    {
        language: "ms-Arab",
        name: "Malay (Jawi)"
    },
    {
        language: "pt",
        name: "Portuguese"
    },
    {
        language: "pt-PT",
        name: "Portuguese (Portugal)"
    },
    {
        language: "bn",
        name: "Bengali"
    },
    {
        language: "ru",
        name: "Russian"
    },
    {
        language: "de",
        name: "German"
    },
    {
        language: "pa",
        name: "Punjabi"
    },
    {
        language: "jw",
        name: "Javanese"
    },
    {
        language: "ko",
        name: "Korean"
    },
    {
        language: "ta",
        name: "Tamil"
    },
    {
        language: "vi",
        name: "Vietnamese"
    },
    {
        language: "te",
        name: "Telugu"
    },
    {
        language: "mr",
        name: "Marathi"
    },
    {
        language: "ur",
        name: "Urdu"
    },
    {
        language: "it",
        name: "Italian"
    },
    {
        language: "tr",
        name: "Turkish"
    },
    {
        language: "ab",
        name: "Abkhaz"
    },
    {
        language: "ace",
        name: "Acehnese"
    },
    {
        language: "ach",
        name: "Acholi"
    },
    {
        language: "aa",
        name: "Afar"
    },
    {
        language: "af",
        name: "Afrikaans"
    },
    {
        language: "sq",
        name: "Albanian"
    },
    {
        language: "alz",
        name: "Alur"
    },
    {
        language: "am",
        name: "Amharic"
    },
    {
        language: "hy",
        name: "Armenian"
    },
    {
        language: "as",
        name: "Assamese"
    },
    {
        language: "av",
        name: "Avar"
    },
    {
        language: "awa",
        name: "Awadhi"
    },
    {
        language: "ay",
        name: "Aymara"
    },
    {
        language: "az",
        name: "Azerbaijani"
    },
    {
        language: "ban",
        name: "Balinese"
    },
    {
        language: "bal",
        name: "Baluchi"
    },
    {
        language: "bm",
        name: "Bambara"
    },
    {
        language: "bci",
        name: "Baoulé"
    },
    {
        language: "ba",
        name: "Bashkir"
    },
    {
        language: "eu",
        name: "Basque"
    },
    {
        language: "btx",
        name: "Batak Karo"
    },
    {
        language: "bts",
        name: "Batak Simalungun"
    },
    {
        language: "bbc",
        name: "Batak Toba"
    },
    {
        language: "be",
        name: "Belarusian"
    },
    {
        language: "bem",
        name: "Bemba"
    },
    {
        language: "bew",
        name: "Betawi"
    },
    {
        language: "bho",
        name: "Bhojpuri"
    },
    {
        language: "bik",
        name: "Bikol"
    },
    {
        language: "bs",
        name: "Bosnian"
    },
    {
        language: "br",
        name: "Breton"
    },
    {
        language: "bg",
        name: "Bulgarian"
    },
    {
        language: "bua",
        name: "Buryat"
    },
    {
        language: "yue",
        name: "Cantonese"
    },
    {
        language: "ca",
        name: "Catalan"
    },
    {
        language: "ceb",
        name: "Cebuano"
    },
    {
        language: "ch",
        name: "Chamorro"
    },
    {
        language: "ce",
        name: "Chechen"
    },
    {
        language: "ny",
        name: "Chichewa"
    },
    {
        language: "chk",
        name: "Chuukese"
    },
    {
        language: "cv",
        name: "Chuvash"
    },
    {
        language: "co",
        name: "Corsican"
    },
    {
        language: "crh",
        name: "Crimean Tatar"
    },
    {
        language: "hr",
        name: "Croatian"
    },
    {
        language: "cs",
        name: "Czech"
    },
    {
        language: "da",
        name: "Danish"
    },
    {
        language: "fa-AF",
        name: "Dari"
    },
    {
        language: "din",
        name: "Dinka"
    },
    {
        language: "dv",
        name: "Divehi"
    },
    {
        language: "doi",
        name: "Dogri"
    },
    {
        language: "dov",
        name: "Dombe"
    },
    {
        language: "nl",
        name: "Dutch"
    },
    {
        language: "dyu",
        name: "Dyula"
    },
    {
        language: "dz",
        name: "Dzongkha"
    },
    {
        language: "eo",
        name: "Esperanto"
    },
    {
        language: "et",
        name: "Estonian"
    },
    {
        language: "ee",
        name: "Ewe"
    },
    {
        language: "fo",
        name: "Faroese"
    },
    {
        language: "fj",
        name: "Fijian"
    },
    {
        language: "tl",
        name: "Filipino"
    },
    {
        language: "fi",
        name: "Finnish"
    },
    {
        language: "fon",
        name: "Fon"
    },
    {
        language: "fy",
        name: "Frisian"
    },
    {
        language: "fur",
        name: "Friulian"
    },
    {
        language: "ff",
        name: "Fulfulde"
    },
    {
        language: "gaa",
        name: "Ga"
    },
    {
        language: "gl",
        name: "Galician"
    },
    {
        language: "lg",
        name: "Ganda"
    },
    {
        language: "ka",
        name: "Georgian"
    },
    {
        language: "el",
        name: "Greek"
    },
    {
        language: "gn",
        name: "Guarani"
    },
    {
        language: "gu",
        name: "Gujarati"
    },
    {
        language: "ht",
        name: "Haitian Creole"
    },
    {
        language: "cnh",
        name: "Hakha Chin"
    },
    {
        language: "ha",
        name: "Hausa"
    },
    {
        language: "haw",
        name: "Hawaiian"
    },
    {
        language: "iw",
        name: "Hebrew"
    },
    {
        language: "hil",
        name: "Hiligaynon"
    },
    {
        language: "hmn",
        name: "Hmong"
    },
    {
        language: "hu",
        name: "Hungarian"
    },
    {
        language: "hrx",
        name: "Hunsrik"
    },
    {
        language: "iba",
        name: "Iban"
    },
    {
        language: "is",
        name: "Icelandic"
    },
    {
        language: "ig",
        name: "Igbo"
    },
    {
        language: "ilo",
        name: "Iloko"
    },
    {
        language: "ga",
        name: "Irish Gaelic"
    },
    {
        language: "jam",
        name: "Jamaican Patois"
    },
    {
        language: "kac",
        name: "Jingpo"
    },
    {
        language: "kl",
        name: "Kalaallisut"
    },
    {
        language: "kn",
        name: "Kannada"
    },
    {
        language: "kr",
        name: "Kanuri"
    },
    {
        language: "pam",
        name: "Kapampangan"
    },
    {
        language: "kk",
        name: "Kazakh"
    },
    {
        language: "kha",
        name: "Khasi"
    },
    {
        language: "km",
        name: "Khmer"
    },
    {
        language: "cgg",
        name: "Kiga"
    },
    {
        language: "kg",
        name: "Kikongo"
    },
    {
        language: "rw",
        name: "Kinyarwanda"
    },
    {
        language: "ktu",
        name: "Kituba"
    },
    {
        language: "trp",
        name: "Kokborok"
    },
    {
        language: "kv",
        name: "Komi"
    },
    {
        language: "gom",
        name: "Konkani"
    },
    {
        language: "kri",
        name: "Krio"
    },
    {
        language: "ku",
        name: "Kurdish (Kurmanji)"
    },
    {
        language: "ckb",
        name: "Kurdish (Sorani)"
    },
    {
        language: "ky",
        name: "Kyrgyz"
    },
    {
        language: "lo",
        name: "Lao"
    },
    {
        language: "ltg",
        name: "Latgalian"
    },
    {
        language: "la",
        name: "Latin"
    },
    {
        language: "lv",
        name: "Latvian"
    },
    {
        language: "lij",
        name: "Ligurian"
    },
    {
        language: "li",
        name: "Limburgan"
    },
    {
        language: "ln",
        name: "Lingala"
    },
    {
        language: "lt",
        name: "Lithuanian"
    },
    {
        language: "lmo",
        name: "Lombard"
    },
    {
        language: "luo",
        name: "Luo"
    },
    {
        language: "lb",
        name: "Luxembourgish"
    },
    {
        language: "mk",
        name: "Macedonian"
    },
    {
        language: "mad",
        name: "Madurese"
    },
    {
        language: "mai",
        name: "Maithili"
    },
    {
        language: "mak",
        name: "Makassar"
    },
    {
        language: "mg",
        name: "Malagasy"
    },
    {
        language: "ml",
        name: "Malayalam"
    },
    {
        language: "mt",
        name: "Maltese"
    },
    {
        language: "mam",
        name: "Mam"
    },
    {
        language: "gv",
        name: "Manx"
    },
    {
        language: "mi",
        name: "Maori"
    },
    {
        language: "mh",
        name: "Marshallese"
    },
    {
        language: "mwr",
        name: "Marwadi"
    },
    {
        language: "mfe",
        name: "Mauritian Creole"
    },
    {
        language: "chm",
        name: "Meadow Mari"
    },
    {
        language: "mni-Mtei",
        name: "Meiteilon (Manipuri)"
    },
    {
        language: "min",
        name: "Minang"
    },
    {
        language: "lus",
        name: "Mizo"
    },
    {
        language: "mn",
        name: "Mongolian"
    },
    {
        language: "my",
        name: "Myanmar (Burmese)"
    },
    {
        language: "nhe",
        name: "Nahuatl (Eastern Huasteca)"
    },
    {
        language: "ndc-ZW",
        name: "Ndau"
    },
    {
        language: "nr",
        name: "Ndebele (South)"
    },
    {
        language: "new",
        name: "Nepalbhasa (Newari)"
    },
    {
        language: "ne",
        name: "Nepali"
    },
    {
        language: "bm-Nkoo",
        name: "NKo"
    },
    {
        language: "nso",
        name: "Northern Sotho"
    },
    {
        language: "no",
        name: "Norwegian"
    },
    {
        language: "nus",
        name: "Nuer"
    },
    {
        language: "oc",
        name: "Occitan"
    },
    {
        language: "or",
        name: "Odia (Oriya)"
    },
    {
        language: "om",
        name: "Oromo"
    },
    {
        language: "os",
        name: "Ossetian"
    },
    {
        language: "pag",
        name: "Pangasinan"
    },
    {
        language: "pap",
        name: "Papiamento"
    },
    {
        language: "ps",
        name: "Pashto"
    },
    {
        language: "fa",
        name: "Persian"
    },
    {
        language: "pl",
        name: "Polish"
    },
    {
        language: "pa-Arab",
        name: "Punjabi (Shahmukhi)"
    },
    {
        language: "kek",
        name: "Q'eqchi'"
    },
    {
        language: "qu",
        name: "Quechua"
    },
    {
        language: "rom",
        name: "Romani"
    },
    {
        language: "ro",
        name: "Romanian"
    },
    {
        language: "rn",
        name: "Rundi"
    },
    {
        language: "se",
        name: "Sami (North)"
    },
    {
        language: "sm",
        name: "Samoan"
    },
    {
        language: "sg",
        name: "Sango"
    },
    {
        language: "sa",
        name: "Sanskrit"
    },
    {
        language: "sat-Latn",
        name: "Santali"
    },
    {
        language: "gd",
        name: "Scots Gaelic"
    },
    {
        language: "sr",
        name: "Serbian"
    },
    {
        language: "st",
        name: "Sesotho"
    },
    {
        language: "crs",
        name: "Seychellois Creole"
    },
    {
        language: "shn",
        name: "Shan"
    },
    {
        language: "sn",
        name: "Shona"
    },
    {
        language: "scn",
        name: "Sicilian"
    },
    {
        language: "szl",
        name: "Silesian"
    },
    {
        language: "sd",
        name: "Sindhi"
    },
    {
        language: "si",
        name: "Sinhala"
    },
    {
        language: "sk",
        name: "Slovak"
    },
    {
        language: "sl",
        name: "Slovenian"
    },
    {
        language: "so",
        name: "Somali"
    },
    {
        language: "su",
        name: "Sundanese"
    },
    {
        language: "sus",
        name: "Susu"
    },
    {
        language: "sw",
        name: "Swahili"
    },
    {
        language: "ss",
        name: "Swati"
    },
    {
        language: "sv",
        name: "Swedish"
    },
    {
        language: "ty",
        name: "Tahitian"
    },
    {
        language: "tg",
        name: "Tajik"
    },
    {
        language: "ber-Latn",
        name: "Tamazight"
    },
    {
        language: "ber",
        name: "Tamazight (Tifinagh)"
    },
    {
        language: "tt",
        name: "Tatar"
    },
    {
        language: "tet",
        name: "Tetum"
    },
    {
        language: "th",
        name: "Thai"
    },
    {
        language: "bo",
        name: "Tibetan"
    },
    {
        language: "ti",
        name: "Tigrinya"
    },
    {
        language: "tiv",
        name: "Tiv"
    },
    {
        language: "tpi",
        name: "Tok Pisin"
    },
    {
        language: "to",
        name: "Tongan"
    },
    {
        language: "ts",
        name: "Tsonga"
    },
    {
        language: "tn",
        name: "Tswana"
    },
    {
        language: "tcy",
        name: "Tulu"
    },
    {
        language: "tum",
        name: "Tumbuka"
    },
    {
        language: "tk",
        name: "Turkmen"
    },
    {
        language: "tyv",
        name: "Tuvan"
    },
    {
        language: "ak",
        name: "Twi"
    },
    {
        language: "udm",
        name: "Udmurt"
    },
    {
        language: "uk",
        name: "Ukrainian"
    },
    {
        language: "ug",
        name: "Uyghur"
    },
    {
        language: "uz",
        name: "Uzbek"
    },
    {
        language: "ve",
        name: "Venda"
    },
    {
        language: "vec",
        name: "Venetian"
    },
    {
        language: "war",
        name: "Waray"
    },
    {
        language: "cy",
        name: "Welsh"
    },
    {
        language: "wo",
        name: "Wolof"
    },
    {
        language: "xh",
        name: "Xhosa"
    },
    {
        language: "sah",
        name: "Yakut"
    },
    {
        language: "yi",
        name: "Yiddish"
    },
    {
        language: "yo",
        name: "Yoruba"
    },
    {
        language: "yua",
        name: "Yucatec Maya"
    },
    {
        language: "zap",
        name: "Zapotec"
    },
    {
        language: "zu",
        name: "Zulu"
    },
    {
        language: "he",
        name: "Hebrew"
    },
    {
        language: "jv",
        name: "Javanese"
    },
    {
        language: "zh-CN",
        name: "Chinese (Simplified)"
    }
];
