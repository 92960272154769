import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useTranslation} from "react-i18next";
import "./errorDeviceContent.css";

interface Props {
    open: any
    handleClose: any
}

export default function ErrorCameraDialog (props: Props) {
    const {t} = useTranslation();

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth maxWidth="xs">
        <DialogTitle>{t("AirCampus Session can't use your camera")}</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={(theme) => ({
                position: "absolute",
                right: 0,
                top: 0,
                color: "#232323"
            })}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <p>{t("Please try one of the following methods:")}</p>
            <ul className="error-device-content">
                <li>{t("Close other apps that might be using your camera")}</li>
                <li>{t("If using an external camera, disconnect and reconnect it")}</li>
                <li>{t("Allow your browser to access your camera")}</li>
                <li>{t("Close and reopen your browser")}</li>
            </ul>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}
