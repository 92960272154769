/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMessageSent = /* GraphQL */ `
  subscription OnMessageSent($channel: String!) {
    onMessageSent(channel: $channel) {
      content
      connectionId
      channel
      resultsId
      language
      isFinal
      sessionId
      name
      translate
      __typename
    }
  }
`;
