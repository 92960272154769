import {useEffect} from "react";
import "./emojiContainer.css";
import {useSelector} from "react-redux";
import type {RootState} from "../../store";

let intervalCreateEmoji: any;

export default function EmojiContainer () {
    const {emojiParticles} = useSelector((state: RootState) => state.sora);

    useEffect(() => {
        createFloatingEmojis().then(null).catch(null);

        return () => {
            clearInterval(intervalCreateEmoji);
        };
    }, [emojiParticles]);

    const sleep = async (ms: number) => {
        return await new Promise(resolve => setTimeout(resolve, ms));
    };

    const createFloatingEmojis = async () => {
        clearInterval(intervalCreateEmoji);
        const emojiContainer = document.getElementById("emoji-container");
        if (!emojiContainer) return;
        if (!emojiParticles.emojiQuantity) return;

        function generateEmoji () {
            console.log(123);
            if (!emojiParticles.emojiType) return;
            if (!emojiParticles.emojiQuantity) return;

            const emojiElement = document.createElement("div");
            emojiElement.classList.add("emoji-particles");
            emojiElement.innerHTML = `<img src="/img/emoji/${emojiParticles.emojiType}.gif" alt="${emojiParticles.emojiType}" />`;

            // Set random horizontal position
            emojiElement.style.left = (Math.random() * 100).toString() + "vw";

            // Set random animation duration
            const animationDuration = Math.random() * 3 + 1;
            emojiElement.style.animationDuration = animationDuration.toString() + "s";
            emojiElement.style.width = (Math.random() * 2 + 1).toString() + "em";

            emojiContainer?.appendChild(emojiElement);

            // Remove the emoji after animation ends
            setTimeout(() => {
                emojiElement.remove();
            }, 5000);
        }

        // Create new emojis at intervals
        for (let i = 0; i < emojiParticles.emojiQuantity; i++) {
            generateEmoji();
            await sleep(200);
        }
    };

    return <div id="emoji-container"></div>;
}
