import React from "react";
import "./subTitle.css";
import {AVATAR_DEFAULT} from "../../constants/constant";
import {getBackgroundUser, handleImageError} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import type {RootState} from "../../store";

interface SubTitleProps {
    name: string
    content: string
    isFinal: boolean
    avatarUrl?: string
    isMine: boolean
    translate?: string
    indexBackground?: number
    translateTranslatedSentences?: string
}
function SubtitleItem (props: SubTitleProps) {
    const {isHideOriginalText, isDisplaySpeakerName, subtitleFontsize} = useSelector((state: RootState) => state.sora);
    const { t } = useTranslation();

    return <div className={`subtitle-item ${props.isMine ? "is-mine" : ""}`}>
        <img className={`avatar ${getBackgroundUser(props.indexBackground || 0)}`} src={props.avatarUrl ? props.avatarUrl : AVATAR_DEFAULT} alt="avatar" onError={handleImageError}/>
        <div className="subtitle-name-content">
            <span className="subtitle-name">{isDisplaySpeakerName ? props.name : ""}</span>
            <div className={`subtitle-content ${props.isFinal ? "is-final" : ""} font-size-${subtitleFontsize}`}>
                {
                    (isHideOriginalText && props.translate)
                        ? null
                        : <span>{props.content} {(!props.isFinal ? t("under construction") : "") as string}</span>
                }
                {
                    props.translate &&
                    <span className={`subtitle-translate ${isHideOriginalText ? "hide-original-text" : ""}`}>
                        {props.translate.toString()} {(!props.isFinal ? t("under construction") : "") as string}
                    </span>
                }
                {
                    props.translateTranslatedSentences &&
                    <span className="subtitle-translate">
                        {props.translateTranslatedSentences.toString()} {(!props.isFinal ? t("under construction") : "") as string}
                    </span>
                }
            </div>
        </div>
    </div>;
}

export default SubtitleItem;
