import {Amplify} from "aws-amplify";
import {onMessageSent} from "../../graphql/subscriptions";
import React, {useEffect, useState} from "react";
import {generateClient} from "aws-amplify/api";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {
    setContentSubTitle,
    setTranslateTranslatedSentences,
    type TranscriptDeepGram
} from "../../services/sora/soraSlice";
import axios from "axios";
import "./appsync.css";
import SettingsIcon from "@mui/icons-material/Settings";
import {PopoverSubtitleSetting} from "../../components/subTitle/popoverSubtitleSetting";
import SubtitleItem from "../../components/subTitle/subtitleItem";
import {decodeHTMLandURL} from "../../utils/utils";
import SubTitleDialog from "../../components/subTitle/subTitleDialog";
import {getMessages} from "../../graphql/queries";
import type {GetSessionResponse} from "../../types/types";
import {useTranslation} from "react-i18next";
import {closeSnackbar, useSnackbar} from "notistack";
import ExcelJS from "exceljs";
import {saveAs} from "file-saver";
import {handleSubtitleTranslationLanguage} from "../../services/sora/soraApi";
import {IconButton, Menu, MenuItem} from "@mui/material";

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const channel = params.get("channel") || "";

interface Translate {
    language: string
    translated_text: string
}

interface Subtitle {
    content: string
    connectionId: string
    channel: string
    resultsId: string
    language: string
    isFinal: boolean
    sessionId: string
    name: string
    created_at: string
    translate: string
    __typename: string
}

interface AppsyncApiKeyResponse {
    api_key: string
}

export default function Appsync () {
    const {
        contentSubTitle,
        subtitleTranslationLanguage,
        isStopAutoScroll,
        isTranslateTranslatedSentences
    } = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const [mapSignalingNotifyMetadata] = useState(new Map<string, string>());
    const [client, setClient] = useState(generateClient());
    const [apiKey, setApiKey] = useState("");
    const [anchorShowSubtitleTranslation, setAnchorShowSubtitleTranslation] = React.useState<HTMLElement | null>(null);
    const openShowSubtitleTranslation = Boolean(anchorShowSubtitleTranslation);
    const [isShowSubTitleDialog, setShowSubTitleDialog] = useState(false);
    const [sessionId, setSessionId] = useState("");
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorMenuEl);

    useEffect(() => {
        getAppsyncApiKey().then(apiKey => {
            setApiKey(apiKey);
        }).catch(err => {
            console.log(err);
        });

        window.onbeforeunload = function (event) {
            event.preventDefault();
            event.returnValue = "true";
            return "true";
        };

        if (!localStorage.getItem("subtitleTranslationLanguage")) {
            setShowSubTitleDialog(true);
        }

        dispatch(setTranslateTranslatedSentences(false));
    }, []);

    useEffect(() => {
        if (!sessionId) return;
        if (!apiKey) return;
        fetchMessages(sessionId).then(null).catch(null);
    }, [sessionId, client]);

    useEffect(() => {
        window.languageAppsync = subtitleTranslationLanguage;
        window.isTranslateTranslatedSentences = isTranslateTranslatedSentences;
    }, [subtitleTranslationLanguage, isTranslateTranslatedSentences]);

    useEffect(() => {
        const config = {
            API: {
                GraphQL: {
                    endpoint: "https://gcsswz5y6fchzp3wfpz5yf5f44.appsync-api.ap-northeast-1.amazonaws.com/graphql",
                    region: "ap-southeast-1",
                    defaultAuthMode: "apiKey",
                    apiKey
                }
            }
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        Amplify.configure(config);
        setClient(generateClient());
    }, [apiKey]);

    useEffect(() => {
        if (!apiKey) return;
        // Subscribe to new messages
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const subscription = client.graphql({query: onMessageSent, variables: {channel}}).subscribe({
            next: async (eventData: any) => {
                const newMsg = eventData.data.onMessageSent as Subtitle;
                if (!newMsg.content) return;
                if (!mapSignalingNotifyMetadata.has(newMsg.connectionId)) {
                    mapSignalingNotifyMetadata.set(newMsg.connectionId, newMsg.name);
                }
                const indexBackground = Array.from(mapSignalingNotifyMetadata.keys()).indexOf(newMsg.connectionId);

                const transcript = decodeHTMLandURL(newMsg.content);
                let translate;
                let translateTranslatedSentences;
                let translateData;
                try {
                    translateData = JSON.parse(newMsg.translate) as Translate[];
                } catch {
                    translateData = [] as Translate[];
                }
                const translateResult = translateData.find(item => item.language === window.languageAppsync);
                if (window.languageAppsync !== newMsg.language && translateResult) {
                    try {
                        translate = decodeHTMLandURL(translateResult.translated_text);
                        if (window.isTranslateTranslatedSentences) {
                            const responseTranslate = await axios.post(`${process.env.REACT_APP_SESSION_API || ""}/google_translate?text=${encodeURIComponent(translate)}&target_lang=${newMsg.language}&source_lang=${window.languageAppsync}`);
                            translateTranslatedSentences = decodeHTMLandURL(responseTranslate.data.translated_text);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                const transcriptDeepGram: TranscriptDeepGram = {
                    resultsId: newMsg.resultsId,
                    connectionId: newMsg.connectionId,
                    name: newMsg.name,
                    transcript,
                    isFinal: newMsg.isFinal,
                    isMine: false,
                    translate,
                    indexBackground,
                    translateTranslatedSentences,
                    language: newMsg.language
                };
                dispatch(setContentSubTitle(transcriptDeepGram));
            },
            error: (error: any) => {
                console.warn(error);
            }
        });

        getSessionId().then(sessionId => {
            setSessionId(sessionId);
        }).catch(err => { console.log(err); });

        return () => subscription.unsubscribe();
    }, [client]);

    useEffect(() => {
        handleSubtitleTranslationLanguage(channel, sessionId, subtitleTranslationLanguage);
    }, [subtitleTranslationLanguage, sessionId]);

    useEffect(() => {
        const beforeunload = () => {
            handleSubtitleTranslationLanguage(channel, sessionId, "0");
        };

        document.addEventListener("beforeunload", beforeunload);

        return () => {
            document.removeEventListener("beforeunload", beforeunload);
        };
    }, [sessionId]);

    const getAppsyncApiKey = async () => {
        const response = await axios.get<AppsyncApiKeyResponse>(`${process.env.REACT_APP_SESSION_API || ""}/appsync_api_key`);
        if (response.status !== 200) {
            return "";
        }
        return response.data.api_key;
    };

    const handleClickShowSubtitleTranslation = () => {
        setAnchorShowSubtitleTranslation(anchorMenuEl);
        handleMenuClose();
    };

    const handleCloseShowSubtitleTranslation = () => {
        setAnchorShowSubtitleTranslation(null);
    };

    useEffect(() => {
        if (!isStopAutoScroll) {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth"
            });
        }

        if (!sessionId) {
            getSessionId().then(sessionId => {
                setSessionId(sessionId);
            }).catch(err => { console.log(err); });
        }
    }, [contentSubTitle]);

    const fetchMessages = async (sessionId: string) => {
        try {
            const messageData = await client.graphql({ query: getMessages, variables: { channel, sessionId } });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const listSubtitle = messageData.data.getMessages as Subtitle[];
            listSubtitle
                .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                .forEach(subtitle => {
                    if (!mapSignalingNotifyMetadata.has(subtitle.connectionId)) {
                        mapSignalingNotifyMetadata.set(subtitle.connectionId, subtitle.name);
                    }
                    const indexBackground = Array.from(mapSignalingNotifyMetadata.keys()).indexOf(subtitle.connectionId);

                    const transcriptDeepGram: TranscriptDeepGram = {
                        resultsId: subtitle.resultsId,
                        connectionId: subtitle.connectionId,
                        name: subtitle.name,
                        transcript: subtitle.content,
                        isFinal: true,
                        isMine: false,
                        indexBackground,
                        language: subtitle.language
                    };
                    dispatch(setContentSubTitle(transcriptDeepGram));
                });
        } catch (err) {
            console.log("error fetching messages", err);
        }
    };

    const getSessionId = async () => {
        const params = {channel_id: channel};
        const response = await axios.post<GetSessionResponse>(`${process.env.REACT_APP_WEBRTC_API || ""}`, params, {
            headers: {
                "x-sora-target": "Sora_20231220.GetSession"
            }
        });
        if (response.status !== 200) return "";
        return response.data.session_id;
    };

    const handleDownload = () => {
        handleMenuClose();
        if (contentSubTitle.length === 0) {
            enqueueSnackbar("No data available", { variant: "error" });
            return;
        }

        const enqueueId = enqueueSnackbar("Downloading", { variant: "info" });

        exportToExcel().then(null).catch(() => {
            enqueueSnackbar("Create excel file error", { variant: "error" });
        }).finally(() => {
            closeSnackbar(enqueueId);
        });
    };

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("字幕");

        worksheet.columns = [
            { header: "発言者名", key: "name", width: 15 },
            { header: "発言日時", key: "created_at", width: 20 },
            { header: "言語", key: "language", width: 6 },
            { header: "内容", key: "transcript", width: 20 }
        ];

        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true };
        });

        contentSubTitle.forEach((item) => {
            worksheet.addRow(item);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `${channel}.xlsx`);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMenuEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };

    return (
        <div>
            <header className="header-admin">
                <div className="logo">
                    <img src="/img/ac-s.svg" alt="Logo"/>
                </div>
                <div className="appsync-subtitle-settings">
                    <IconButton className="btn-subtitle-settings" onClick={handleMenuClick}>
                        <SettingsIcon style={{ color: "#ffffff" }} />
                    </IconButton>
                    <Menu
                        id="menu-appsync"
                        anchorEl={anchorMenuEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleClickShowSubtitleTranslation}>{t("Translation settings")}</MenuItem>
                        <MenuItem onClick={handleDownload}>{t("Download subtitles")}</MenuItem>
                    </Menu>
                </div>
            </header>
            <div className="admin-body">
                <div className="appsync-sub-title">
                    {
                        contentSubTitle &&
                        <div className="appsync-subtitle-list">
                            {
                                contentSubTitle.slice(-100).map((sub, index) => {
                                    return <SubtitleItem key={index}
                                                         name={sub.name}
                                                         content={sub.transcript}
                                                         isFinal={sub.isFinal} isMine={sub.isMine}
                                                         avatarUrl={sub.avatarUrl}
                                                         translate={sub.translate}
                                                         indexBackground={sub.indexBackground}
                                                         translateTranslatedSentences={sub.translateTranslatedSentences}/>;
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <PopoverSubtitleSetting anchorShowSubtitleTranslation={anchorShowSubtitleTranslation}
                                    handleCloseShowSubtitleTranslation={handleCloseShowSubtitleTranslation}
                                    openShowSubtitleTranslation={openShowSubtitleTranslation}
                                    isAppsyncPage={true}
                                    channelId={channel}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}/>
            <SubTitleDialog open={isShowSubTitleDialog} handleClose={() => { setShowSubTitleDialog(false); }} />
        </div>
    );
}
