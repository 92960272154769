import React, {useEffect} from "react";
import "./App.css";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Meeting from "./pages/meeting/meeting";
import {Provider} from "react-redux";
import store from "./store";
import PageNotFound from "./components/error/notFound/pageNotFound";
import {SnackbarProvider} from "notistack";
import {Admin} from "./pages/settings/mychannel";
import {Channel} from "./pages/settings/channel/channel";
import {
    CHANNEL_LIMIT,
    MEETING_TYPE,
    PATH_ACCESS_LOG,
    PATH_ADMIN,
    PATH_ADMIN_CHANNEL,
    PATH_ADMINISTRATOR_SETUP,
    PATH_FORUM,
    PATH_MEETING,
    PATH_RECORD
} from "./constants/constant";
import SnackbarCloseButton from "./components/icon/snackbarCloseButton";
import AuthRoute from "./components/privateRoute/authRoute";
import i18next from "i18next";
import {StyledMaterialDesignContent} from "./components/styled/notistackStyled";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Record} from "./pages/settings/record";
import AccessLog from "./pages/settings/access-log";
import {getRoom} from "./services/sora/soraApi";
import SetupAdministrator from "./pages/settings/setupAdministrator/setupAdministrator";
import type {ListenLiveClient} from "@deepgram/sdk";
import Appsync from "./pages/appsync/appsync";

const router = createBrowserRouter([
    {
        path: "/",
        element: <PageNotFound/>
    },
    {
        path: PATH_MEETING,
        element: <Meeting/>,
        loader: ({ params }) => {
            if (params.channelId && !CHANNEL_LIMIT.includes(params.channelId)) {
                throw Error("Channel limit");
            }
            return null;
        },
        errorElement: <PageNotFound/>
    },
    {
        path: PATH_FORUM,
        element: <Meeting/>,
        loader: async ({ params}) => {
            if (MEETING_TYPE === params.type && params.id) {
                try {
                    return await getRoom(params.id);
                } catch (err) {
                    throw new Error("Invalid parameters");
                }
            }
        },
        errorElement: <PageNotFound/>
    },
    {
        path: PATH_ADMIN,
        element: <AuthRoute component={Admin}/>
    },
    {
        path: PATH_ADMIN_CHANNEL,
        element: <AuthRoute component={Channel}/>
    },
    {
        path: PATH_RECORD,
        element: <AuthRoute component={Record}/>
    },
    {
        path: PATH_ACCESS_LOG,
        element: <AuthRoute component={AccessLog}/>
    },
    {
        path: PATH_ADMINISTRATOR_SETUP,
        element: <AuthRoute component={SetupAdministrator} checkAdmin={true}/>
    },
    {
        path: "/appsync",
        element: <Appsync/>
    }
]);

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext
        nameFakeVideoTrack: string
        redirectParam: boolean
        socketDeepGram: ListenLiveClient
        microphoneDeepGram: MediaRecorder
        languageAppsync: string
        isTranslateTranslatedSentences: boolean
    }
}

const theme = createTheme({
    typography: {
        fontFamily: "\"Noto Sans JP\", sans-serif",
        fontWeightMedium: "normal"
    }
});

function App () {
    const currentLanguage = i18next.language;
    useEffect(() => {
        let lang = currentLanguage;
        if (currentLanguage !== "ja" && currentLanguage !== "en") {
            lang = "en";
        }
        document.documentElement.setAttribute("lang", lang);
    }, [currentLanguage]);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                                  autoHideDuration={5000}
                                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                  hideIconVariant={true}
                                  maxSnack={10}
                                  Components={{
                                      error: StyledMaterialDesignContent,
                                      default: StyledMaterialDesignContent,
                                      info: StyledMaterialDesignContent,
                                      success: StyledMaterialDesignContent
                                  }}>
                    <RouterProvider router={router}/>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    );
}
export default App;
