import type React from "react";
import {ALLOW_ACCESS_TYPE, AVATAR_DEFAULT, REPLACE_URL_TO_TAG_A, ROLE_CONNECT} from "../constants/constant";
import type {EnqueueSnackbar} from "notistack";
import {emojiList} from "../components/chat/emoji";

export const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = AVATAR_DEFAULT;
};

export const getBackgroundUser = (key: number) => {
    // 9 background color
    const index = key % 9;
    return `background-color-user-not-avatar${index ? `-${index}` : ""}`;
};

export const secondsToMs = (seconds: number) => {
    const m = Math.floor(seconds / 60);
    const s = Math.floor(seconds % 60);

    const formattedM = m.toString();
    const formattedS = s.toString().padStart(2, "0");
    return `${formattedM}:${formattedS}`;
};

export const timeMsToSeconds = (time: string) => {
    const timeComponents = time.split(":").map(Number);
    let totalSeconds = 0;

    if (timeComponents.length === 2) {
        const [minutes, seconds] = timeComponents;
        totalSeconds = (minutes * 60) + seconds;
    }

    return totalSeconds;
};

export const secondsToMinutes = (seconds: number) => {
    return seconds / 60;
};

export const stringFormat = (str: string, ...args: any[]) => {
    return str.replace(/{(\d+)}/g, (match, index) => args[index] || "");
};

export const getUrlMeeting = (channelId: string, role?: string) => {
    const params = new URLSearchParams();
    if (role) {
        params.append(ROLE_CONNECT, role);
    }
    let url = `${window.location.origin}/mtg/${channelId}`;
    if ((params as any).size) {
        url += `?${params.toString()}`;
    }
    return url;
};

// Function to generate a random RGB color
export const getRandomColor = () => {
    const color = ["#9CA7B2", "#FF7F7E", "#C4AB2C", "#70D43C", "#43DB90", "#59BEF2", "#A695FF", "#FF67FF", "#FF7C93"];
    const random = Math.floor(Math.random() * color.length);
    return color[random];
};

export const getRoomType = (t: any, allowUserAccess: number, allowGuestAccess: number) => {
    if (allowUserAccess !== ALLOW_ACCESS_TYPE.allow && allowUserAccess !== ALLOW_ACCESS_TYPE.owner_allow && allowUserAccess !== ALLOW_ACCESS_TYPE.user_allow) {
        allowUserAccess = ALLOW_ACCESS_TYPE.owner_allow;
    }
    if (allowGuestAccess !== ALLOW_ACCESS_TYPE.owner_allow && allowGuestAccess !== ALLOW_ACCESS_TYPE.user_allow) {
        allowGuestAccess = ALLOW_ACCESS_TYPE.owner_allow;
    }

    let userType = `【${(t("AirCampus User") as string)}】：`;
    if (allowUserAccess === ALLOW_ACCESS_TYPE.owner_allow) {
        userType += t("You can enter if the owner approves") as string;
    } else if (allowUserAccess === ALLOW_ACCESS_TYPE.user_allow) {
        userType += t("You can enter the room if the AirCampus user who is in the room approves it") as string;
    } else if (allowUserAccess === ALLOW_ACCESS_TYPE.allow) {
        userType += t("If you know the URL, you can enter the room without approval") as string;
    }

    let guestType = `【${(t("guest") as string)}】：`;
    if (allowGuestAccess === ALLOW_ACCESS_TYPE.owner_allow) {
        guestType += t("You can enter if the owner approves") as string;
    } else if (allowGuestAccess === ALLOW_ACCESS_TYPE.user_allow) {
        guestType += t("You can enter the room if the AirCampus user who is in the room approves it") as string;
    }

    return `${userType}<br />${guestType}`;
};

export const replaceUrlToTagA = (text: string) => {
    const urls = text.match(REPLACE_URL_TO_TAG_A);
    if (urls) {
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            const link = `<a href="${url}" target="_blank">${url}</a>`;
            if (!text.includes(link)) {
                text = text.replaceAll(url, link);
            }
        }
    }
    return text;
};

export const secondsToHms = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const hDisplay = h.toString().padStart(2, "0");
    const mDisplay = m.toString().padStart(2, "0");
    const sDisplay = s.toString().padStart(2, "0");

    return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const convertGradientListToTimeSpanList = (gradientList: number[]) => {
    const result = [];
    let start = null;

    for (let i = 0; i < gradientList.length; i++) {
        if (gradientList[i] === 1) {
            if (start === null) {
                start = i;
            }
        } else {
            if (start !== null) {
                result.push({ startTime: secondsToHms(start), duration: i - start });
                start = null;
            }
        }
    }

    if (start !== null) {
        result.push({ startTime: secondsToHms(start), duration: gradientList.length - start});
    }

    return result;
};

export const timeToSeconds = (timeString: string) => {
    const parts = timeString.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);

    return (hours * 3600) + (minutes * 60) + seconds;
};

export const hasEllipsis = (element: HTMLDivElement) => {
    if (!element) return false;

    // Get the actual width of the element
    const elementWidth = element.clientWidth;

    // Get the actual width of the content inside the element
    const contentWidth = element.scrollWidth;

    // Compare the width of the element and the content
    return contentWidth > elementWidth;
};

export const handleErrorMessage = (err: any, enqueueSnackbar: EnqueueSnackbar) => {
    if (err.response?.message) {
        enqueueSnackbar(err.response.message, { variant: "error" });
    } else if (err.response?.data?.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
    } else {
        enqueueSnackbar("Error", { variant: "error" });
    }
};

function onlyEmoji (text: string) {
    const regex = /^({\w+})+$/;
    return regex.test(text);
}

export const replaceEmoji = (text: string) => {
    const isOnlyEmoji = onlyEmoji(text);
    return text.replace(/{(.*?)}/g, function (match: string, p1: string) {
        if (emojiList.some(emoji => emoji.name === p1)) {
            return `<img class="chat-emoji ${isOnlyEmoji ? "only-emoji" : ""}" src="/img/emoji/${p1}.gif" alt="${p1}" />`;
        }
        return match;
    });
};

export const clipboardLink = (url: string, enqueueSnackbar: EnqueueSnackbar, t: any) => {
    navigator.clipboard.writeText(url).then(r => {
        enqueueSnackbar(t("copied"), { variant: "success", autoHideDuration: 1000});
    }).catch(null);
};

export const getUrlAppsync = (channelId: string) => {
    return `${window.location.origin}/appsync?channel=${channelId}`;
};

export const decodeHTMLEntities = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.documentElement.textContent || "";
};

export const decodeHTMLandURL = (encodedStr: string) => {
    const urlDecoded = decodeURIComponent(encodedStr);
    return decodeHTMLEntities(urlDecoded);
};

export const endsWithPeriodOrFullWidthPeriod = (str: string) => {
    const regex = /[。.]$/;
    return regex.test(str);
};
